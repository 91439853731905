import React, { createContext, useCallback, useEffect, useState } from "react";
import { CubeProvider } from "@cubejs-client/react";
import { statisticsAPI } from "../services/statisticsAPI";

const MetaContext = createContext();

function CubeQueryProvider({ cubejsApi, children }) {
  const [meta, setMeta] = useState({
    measures: [],
    dimensions: [],
  });
  const [error, setError] = useState();

  if (error) {
    throw error;
  }

  const handleRequestMeta = useCallback(async () => {
    try {
      const result = await statisticsAPI.get("/cube.json");
      setMeta(processMetaResult(result?.data));
    } catch (error) {
      setError(error);
    }
  }, []);

  useEffect(() => {
    handleRequestMeta();
  }, [handleRequestMeta]);

  return (
    <CubeProvider cubeApi={cubejsApi}>
      <MetaContext.Provider value={meta}>{children}</MetaContext.Provider>
    </CubeProvider>
  );
}

function processMetaResult(metaResult) {
  return {
    measures: metaResult.cubes
      .filter(
        (cube) =>
          cube.title.startsWith("statistics2_") ||
          cube.title.startsWith("boundaries_")
      )
      .flatMap((cube) =>
        cube.measures.map((measure) => {
          const { level, category } = parseCube(cube.title);
          const [statistic, unit] = measure.shortTitle.split("__");
          return {
            name: measure.name,
            title: measure.shortTitle,
            level,
            category,
            statistic,
            unit,
          };
        })
      ),
    dimensions: metaResult.cubes.flatMap((cube) =>
      cube.dimensions.map((dimension) => {
        const { level, category } = parseCube(cube.title);
        return {
          level,
          category,
          name: dimension.name,
          title: dimension.shortTitle,
        };
      })
    ),
  };
}

function parseCube(cubeTitle) {
  let matches = cubeTitle.match(
    /statistics2_(?<level>tract|block_group)_(?<category>\w+)/
  );
  if (matches === null) {
    matches = cubeTitle.match(/boundaries_(?<level>tract|block_group)/);
  }
  return {
    level: matches.groups.level,
    category: matches.groups.category || null,
  };
}

export default CubeQueryProvider;
export { MetaContext, processMetaResult, parseCube };
