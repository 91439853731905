import { Col, Menu, Row } from "antd";
import { Link } from "react-router-dom";
import Logo from "../../Logo";
import {
  BarChartOutlined,
  DownloadOutlined,
  MailOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { PRIVATE_MENU_ITEMS, URL_CONTACT } from "../../../constants";

function ChildrenHeader({ handleClickPrivateMenu }) {
  return (
    <Row
      justify="space-between"
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%",
      }}
    >
      <Col lg={8} md={10} sm={12} xs={23}>
        <Link to="/">
          <Logo type="secondary" />
        </Link>
      </Col>
      <Col lg={16} md={14} sm={12} xs={1}>
        <Menu
          mode="horizontal"
          theme="dark"
          style={{
            textAlign: "right",
            background: "none",
            justifyContent: "flex-end",
          }}
        >
          <Menu.Item
            key="atlas"
            icon={<BarChartOutlined />}
            style={{ color: "#fff" }}
          >
            <Link to="/atlas" style={{ color: "#fff" }}>
              <FormattedMessage
                description="Go to the atlas"
                defaultMessage="Atlas"
              />
            </Link>
          </Menu.Item>

          <Menu.Item
            key="download"
            icon={<DownloadOutlined />}
            onClick={() => handleClickPrivateMenu(PRIVATE_MENU_ITEMS.DOWNLOAD)}
            style={{ color: "#fff" }}
          >
            <FormattedMessage
              description="Download link"
              defaultMessage="Download"
              style={{ color: "#fff" }}
            />
          </Menu.Item>
          <Menu.Item
            key="api"
            icon={<ApiOutlined />}
            onClick={() => handleClickPrivateMenu(PRIVATE_MENU_ITEMS.API)}
            style={{ color: "#fff" }}
          >
            <FormattedMessage
              description="API Link"
              defaultMessage="API"
              style={{ color: "#fff" }}
            />
          </Menu.Item>

          <Menu.Item
            key="contact"
            icon={<MailOutlined />}
            style={{ color: "#fff" }}
          >
            <a href={URL_CONTACT} style={{ color: "#fff" }}>
              <FormattedMessage
                description="Contact link"
                defaultMessage="Contact"
              />
            </a>
          </Menu.Item>
        </Menu>
      </Col>
    </Row>
  );
}

export default ChildrenHeader;
