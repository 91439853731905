import _some from "lodash/fp/some";
import _isNull from "lodash/fp/isNull";
import _isUndefined from "lodash/fp/isUndefined";

export const incompleteSelection = ({
  selection,
  valueMeasure,
  quartileMeasure,
}) => {
  return (
    _some(
      (attribute) =>
        _isNull(selection[attribute]) || _isUndefined(selection[attribute])
    )(["level", "category", "statistic", "unit"]) ||
    _isNull(valueMeasure) ||
    _isNull(quartileMeasure)
  );
};
