import { MAPS_CONSTANTS } from "../constants";

export const getAggregatedFipsColor = (statisticsData) => {
  const aggregatedByColor = statisticsData?.reduce((acc, item) => {
    const color =
      MAPS_CONSTANTS.STYLE_SLICE[item.quartile]?.fillColor ||
      MAPS_CONSTANTS.STYLE_SLICE.null.fillColor;

    // Check if the color object already exists in the accumulator array
    if (!acc[color]) {
      acc[color] = [];
    }

    // Add the current fips to the array for the color
    acc[color].push(item.fips);

    return acc;
  }, {});

  const aggregatedFipsColor = Object.entries(aggregatedByColor).flatMap(
    ([color, geoids]) => [["in", ["get", "GEOID"], ["literal", geoids]], color]
  );

  return aggregatedFipsColor;
};
