import React from "react";
import { Button } from "antd";
import "./ActionButton.css";

function ActionButton({ url, textButton, width="159px", height="44px" }) {
  return (
    <Button
      href={url}
      type="default"
      className="action-button"
      style={{
        width: width,
        height: height,
      }}
      size="large"
    >
      {textButton}
    </Button>
  );
}

export default ActionButton;
