import { getErrorAPIMessage } from "../../errors/getErrorAPIMessage";
import { api } from "../axios";

export const resetPassword = async ({ email, code, newPassword }) => {
  try {
    await api.post("/auth/forgot-password", {
      email,
      new_password: newPassword,
      code,
    });
  } catch (error) {
    throw new Error(getErrorAPIMessage(error));
  }
};
