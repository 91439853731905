// Todo: Ideally we could generate a file with the data-import step so that we don't have to maintain these values.
export const demographics = {
  statistics: [
    {
      subgroup: "age",
      options: ["18_to_29", "30_to_64", "65_to_75"],
    },
    {
      subgroup: "ethnicity",
      options: ["hispanic", "non_hispanic"],
    },
    {
      subgroup: "race",
      options: ["asian", "black", "white"],
    },
    {
      subgroup: "sex",
      options: ["male", "female"],
    },
  ],
};
