import { create } from "zustand";

export const useMapBoxParams = create((set) => ({
  level: "tract",
  countyFilter: ["37"],
  listYears: [],
  selectedYear: [],
  yearsLoading: true,
  statistics: {
    isLoading: true,
    data: [],
    demographics: {},
  },
  selectedFip: "",
  listDemographics: [],
  selectedDemographic: {},
  demographicsLoading: true,
  setLevel: (level) => set({ level }),
  setCountyFilter: (countyFilter) => set({ countyFilter }),
  setListYears: (listYears) => set({ listYears }),
  setSelectedYear: (selectedYear) => set({ selectedYear }),
  setYearsLoading: (yearsLoading) => set({ yearsLoading }),
  setStatistics: (statistics) => set({ statistics }),
  setSelectedFip: (selectedFip) => set({ selectedFip }),
  setListDemographics: (listDemographics) => set({ listDemographics }),
  setSelectedDemographic: (selectedDemographic) => set({ selectedDemographic }),
  setDemographicsLoading: (demographicsLoading) => set({ demographicsLoading }),
}));
