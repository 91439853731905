const URL_CONTACT = "mailto:DukeCTSI@dm.duke.edu";
const URL_COLLABORATE = "mailto:nrupen.bhavsar@duke.edu";
const URL_API = window._env_.REACT_APP_URL_API;
const URL_BOUNDERY = !!window._env_.REACT_APP_URL_BOUNDERY
  ? window._env_.REACT_APP_URL_BOUNDERY
  : "https://duke-public.s3.us-west-2.amazonaws.com/counties";
const URL_MAPBOX_TOKEN = !!window._env_.REACT_APP_MAPBOX_TOKEN
  ? window._env_.REACT_APP_MAPBOX_TOKEN
  : "pk.eyJ1Ijoidm9sa2VyeiIsImEiOiJjbHlocnNqOWgwN2x4Mm1vZjkwaWVrd3dmIn0.wncHpu7lCu_aC9I9244GSw";

const URL_MAPBOX_TILES = !!window._env_.REACT_APP_MAPBOX_TILES
  ? window._env_.REACT_APP_MAPBOX_TILES
  : "https://duke-public.s3.us-west-2.amazonaws.com/tiles_3";

const URL_STATISTICS = !!window._env_.REACT_APP_URL_STATISTICS
  ? window._env_.REACT_APP_URL_STATISTICS
  : "https://duke-public.s3.us-west-2.amazonaws.com/statistics-data";

const AZURE_MONITOR_KEY = !!window._env_.REACT_APP_AZURE_MONITOR_KEY
  ? window._env_.REACT_APP_AZURE_MONITOR_KEY
  : "8f0749bf-6c6d-4e4e-ad39-0f7aa70be81e";

const AZURE_INGESTION_URL = !!window._env_.REACT_APP_AZURE_INGESTION_URL
  ? window._env_.REACT_APP_AZURE_INGESTION_URL
  : "https://eastus2-3.in.applicationinsights.azure.com/";

const AZURE_LIVE_URL = !!window._env_.REACT_APP_AZURE_LIVE_URL
  ? window._env_.REACT_APP_AZURE_LIVE_URL
  : "https://eastus2.livediagnostics.monitor.azure.com/";

const AZURE_APPLICATION_ID = !!window._env_.REACT_APP_AZURE_APPLICATION_ID
  ? window._env_.REACT_APP_AZURE_APPLICATION_ID
  : "14fb5c57-9108-403b-8e19-1b0e0475ff69";

export {
  URL_CONTACT,
  URL_API,
  URL_BOUNDERY,
  URL_MAPBOX_TOKEN,
  URL_MAPBOX_TILES,
  URL_COLLABORATE,
  URL_STATISTICS,
  AZURE_MONITOR_KEY,
  AZURE_INGESTION_URL,
  AZURE_LIVE_URL,
  AZURE_APPLICATION_ID,
};
