import { getErrorAPIMessage } from "../../errors/getErrorAPIMessage";
import { api } from "../axios";

export const login = async ({ email, password }) => {
  try {
    const response = await api.post("/auth/login", { email, password });
    return {
      accessToken: response?.data?.access_token,
    };
  } catch (error) {
    throw new Error(getErrorAPIMessage(error));
  }
};
