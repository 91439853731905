import { getErrorAPIMessage } from "../../errors/getErrorAPIMessage";
import { api } from "../axios";

export const createAccount = async ({
  name,
  email,
  password,
  company = "",
  employerCategory,
  employerPosition = "",
  intendToUse = "",
  acceptedTerms,
}) => {
  try {
    const response = await api.post("/users/", {
      name,
      email,
      password,
      company,
      employer_category: employerCategory,
      employer_position: employerPosition,
      intend_to_use: intendToUse,
      accepted_terms: acceptedTerms,
    });
    return response.data;
  } catch (error) {
    throw new Error(getErrorAPIMessage(error));
  }
};
