import _map from "lodash/fp/map";
import _pipe from "lodash/fp/pipe";

export const handleFormattedStatisticsMapResponse = ({
  resultData,
  category,
}) => {
  let data = getStatisticsData({
    resultSet: resultData,
  });

  if (category === "food_access") {
    data = data.map((d) => ({
      ...d,
      quartile: d.value === 0 ? 1 : 3,
    }));
  }

  return {
    data,
  };
};

function getStatisticsData({ resultSet }) {
  return _pipe(
    _map((item) => ({
      fips: item["fips"],
      value: item["percent_cont"],
      quartile: item["quartile"],
      year: item["year"],
    }))
  )(resultSet);
}
