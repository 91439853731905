import React from "react";
import { useCubeQuery } from "@cubejs-client/react";

import _every from "lodash/fp/every";
import _get from "lodash/fp/get";
import _isEqual from "lodash/fp/isEqual";
import _keys from "lodash/fp/keys";
import _pick from "lodash/fp/pick";

import useRetryEffect from "../errors/useRetryEffect";
import { MetaContext } from "./CubeQueryProvider";

import { LIMIT_RESULT_ROWS, STATES } from "../constants";

/**
 * Look up measures. Any of the selection criteria may be `undefined` to omit
 * filtering on it. Note that specifying `null` filters to values that are
 * null.
 *
 * @return {Array.{
 *   name: string,
 *   title: string,
 *   cube: string,
 *   statistic: string,
 *   unit: string
 * }}
 */
function useMeasures({ level, category, statistic, unit } = {}) {
  const meta = React.useContext(MetaContext);
  return meta.measures.filter((measure) => {
    const conditions = [
      level === undefined || measure.level === level,
      category === undefined || measure.category === category,
      statistic === undefined || measure.statistic === statistic,
      unit === undefined || measure.unit === unit,
    ];
    return _every(Boolean)(conditions);
  });
}

/**
 * Get a dimension. All selection criteria must be provided.
 *
 * @return the dimension name
 */
function getDimension({ level, category }, name) {
  if (category === null) {
    return `boundaries_${level}.${name}`;
  } else {
    return `statistics2_${level}_${category}.${name}`;
  }
}

/**
 * Limit queries to the specified year.
 */

function getYearFilter({ level, category, year, isV2API = false }) {
  return {
    member: isV2API ? "year" : getDimension({ level, category }, "year"),
    operator: "equals",
    values: year?.length ? year : ["undefined"],
  };
}

/**
 * Limit queries to the specified county.
 */

function getCountyFilter({ level, category, countyFilter, isV2API = false }) {
  if (countyFilter === undefined) {
    // countyFilter = ["37063"];
    countyFilter = STATES.map((c) => c.zipcode).filter((c) => c !== "");
  }
  if (countyFilter.length === 1 && countyFilter[0] === "") {
    // Passing an empty string returns coordinates for the entire state of NC. Instead just get counties with data.
    countyFilter = STATES.map((c) => c.zipcode).filter((c) => c !== "");
  }
  return {
    member: isV2API ? "fips" : getDimension({ level, category }, "fips"),
    operator: "startsWith",
    values: countyFilter,
  };
}

function useQuery(query, options = {}) {
  const meta = React.useContext(MetaContext);
  const loadingMeta = meta.measures.length === 0;
  const result = useCubeQuery(
    {
      ...query,
      limit: LIMIT_RESULT_ROWS,
      dimensions: (query?.dimensions || []).filter(Boolean),
    },
    {
      ...options,
      skip: options?.skip || loadingMeta,
      resetResultSetOnChange: true,
    }
  );
  // useCubeQuery can return the results for the old query even when the query
  // has changed. This is supposed to be fixed by the `resetResultSetOnChange`
  // option, but the reset doesn't seem to happen immediately.
  const executedQuery =
    _get("resultSet.loadResponse.results[0].query")(result) || {};
  const queryMatches = _isEqual(_pick(_keys(query))(executedQuery), query);
  if (!queryMatches) {
    result.resultSet = null;
  }
  useRetryEffect(result.error, result.refetch);
  return {
    ...result,
    isLoading: loadingMeta || result.isLoading,
  };
}

export { useMeasures, useQuery, getDimension, getCountyFilter, getYearFilter };
