const MIN_ZOOM = 4;
const MAX_ZOOM = 15;
const MIN_BORDER_WEIGHT = 0.1;
const MAX_BORDER_WEIGHT = 1;

const DURHAM_COORDINATES = [35.994, -78.8986];
const EUA_COORDINATES = [-98.5795, 39.8283];
const LIMIT_BOUNDS = [
  [-125.00165, 24.396308], // Sudoeste (canto inferior esquerdo)
  [-66.93457, 49.384358], // Nordeste (canto superior direito)
];

const STYLE_SLICE = {
  null: {
    fillColor: "rgb(220, 220, 220)",
  },
  1: {
    fillColor: "#B4e1b9",
  },
  2: {
    fillColor: "#67c4be",
  },
  3: {
    fillColor: "#39a0bf",
  },
  4: {
    fillColor: "#2b70b1",
  },
  5: {
    fillColor: "#253494",
  },
};

export const MAPS_CONSTANTS = {
  MIN_ZOOM,
  MAX_ZOOM,
  MIN_BORDER_WEIGHT,
  MAX_BORDER_WEIGHT,
  DURHAM_COORDINATES,
  LIMIT_BOUNDS,
  EUA_COORDINATES,
  STYLE_SLICE,
};
