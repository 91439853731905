import InputDefault from "./InputDefault";
import InputPassword from "./InputPassword";
import InputCode from "./InputCode";

function Input({ ...props }) {
  return <InputDefault {...props} />;
}

Input.Password = InputPassword;
Input.Code = InputCode;

export default Input;
