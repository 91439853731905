import { defineMessages } from "react-intl";

const MESSAGES = defineMessages({
  count: {
    id: "count",
    defaultMessage: "count",
  },
  percent: {
    id: "percent",
    defaultMessage: "percent",
  },
  per_capita: {
    id: "per_capita",
    defaultMessage: "Per Capita",
  },
  median_dollars: {
    id: "median_dollars",
    defaultMessage: "$",
  },
  median_concentration: {
    id: "median_concentration",
    defaultMessage: "median concentration",
  },
  median: {
    id: "median",
    defaultMessage: "Median",
  },
});

export default MESSAGES;
