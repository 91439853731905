import { useCallback, useEffect, useState } from "react";

export const useTimer = (initialCounter = 60) => {
  const [timer, setTimer] = useState(initialCounter);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const resetTimer = useCallback(() => {
    setTimer(initialCounter);
  }, [initialCounter]);

  return { timer, resetTimer };
};
