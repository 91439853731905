import _every from "lodash/fp/every";
import _filter from "lodash/fp/filter";
import _getOr from "lodash/fp/getOr";
import _groupBy from "lodash/fp/groupBy";
import _map from "lodash/fp/map";
import _mapValues from "lodash/fp/mapValues";
import _omit from "lodash/fp/omit";
import _pipe from "lodash/fp/pipe";
import _uniq from "lodash/fp/uniq";

import {
  getDimension,
  getCountyFilter,
  getYearFilter,
  useMeasures,
  useQuery,
} from "../../../cube";
import { incompleteSelection } from "../../../utils";

function useStatistics({
  selection,
  isDownload = false,
  yearsLoading = false,
  selectedState = "",
}) {
  const formattedSelection = {
    ...selection,
    year: selection?.year?.length
      ? [selection?.year]
      : selection?.selectedYears,
    // states: selection?.states?.length ?
    //   selection?.states : null
  };

  // TODO: once categorical value is supported remove the specific check below
  const _measure = formattedSelection.unit
    ? formattedSelection.unit.concat(
        formattedSelection.statistic === `coi` ? `_quintile` : `_quartile`
      )
    : formattedSelection.unit;
  const [{ name: valueMeasure = null } = {}] = useMeasures(formattedSelection);
  const [{ name: quartileMeasure = null } = {}] = useMeasures({
    ...formattedSelection,
    unit: _measure,
  });

  const noHasCountyFilterAtAtlas =
    !isDownload && !formattedSelection?.countyFilter?.length;

  const query = {
    measures: [valueMeasure, quartileMeasure],
    dimensions: [
      getDimension(formattedSelection, "fips"),
      getDimension(formattedSelection, "year"),
      getDimension(formattedSelection, "demographic_type_primary"),
      getDimension(formattedSelection, "demographic_value_primary"),
      getDimension(formattedSelection, "demographic_type_secondary"),
      getDimension(formattedSelection, "demographic_value_secondary"),
    ],
    filters: [
      getCountyFilter({
        ...formattedSelection,
        countyFilter: [selectedState],
      }),
      getYearFilter(formattedSelection),
      {
        member: valueMeasure,
        operator: "set",
      },
    ],
  };

  let result = useQuery(query, {
    skip:
      incompleteSelection({
        selection,
        valueMeasure,
        quartileMeasure,
      }) ||
      !formattedSelection?.year ||
      noHasCountyFilterAtAtlas ||
      yearsLoading,
  });

  if (!result.isLoading && result.resultSet) {
    let data = !isDownload
      ? getStatisticsData({
          resultSet: result.resultSet,
          selection,
          valueMeasure,
          quartileMeasure,
        })
      : getStatisticsForDownload({
          resultSet: result.resultSet,
          selection,
          valueMeasure,
          quartileMeasure,
        });

    /**
    This is a temporary workaround. Unlike all other variables which are split into quartiles or quintiles, food
    access variables are "yes" or "no" (0,1). This allows 0 or 1 to be displayed with consistent colors on the map. Ideally
    this would be done on the backend but doing so would require significant changes in the functionality fo the front end
    **/
    if (selection.category === "food_access" && !isDownload) {
      data = data.map((d) => ({
        ...d,
        quartile: d.value === 0 ? 1 : 3,
      }));
    }

    return {
      isLoading: false,
      data,
      demographics: getDemographics(selection, result.resultSet),
    };
  }
  return {
    isLoading: true,
    data: null,
    years: null,
    demographics: null,
  };
}

function getStatisticsData({
  resultSet,
  selection,
  valueMeasure,
  quartileMeasure,
}) {
  return _pipe(
    _filter((item) =>
      _every(Boolean)([
        item[getDimension(selection, "year")] ===
          _getOr(null, "year")(selection),
        item[getDimension(selection, "demographic_type_primary")] ===
          _getOr(null, "primaryDemographic.type")(selection),
        item[getDimension(selection, "demographic_value_primary")] ===
          _getOr(null, "primaryDemographic.value")(selection),
      ])
    ),
    _map((item) => ({
      fips: item[getDimension(selection, "fips")],
      value: item[valueMeasure],
      quartile: item[quartileMeasure],
      year: item[getDimension(selection, "year")],
    }))
  )(resultSet.rawData());
}

// TODO: DRY this
function getStatisticsForDownload({
  resultSet,
  selection,
  valueMeasure,
  quartileMeasure,
}) {
  return _pipe(
    _filter((item) =>
      _every(Boolean)([
        item[getDimension(selection, "demographic_type_primary")] ===
          _getOr(null, "primaryDemographic.type")(selection),
        item[getDimension(selection, "demographic_value_primary")] ===
          _getOr(null, "primaryDemographic.value")(selection),
      ])
    ),
    _map((item) => ({
      fips: item[getDimension(selection, "fips")],
      value: item[valueMeasure],
      quartile: item[quartileMeasure],
      year: item[getDimension(selection, "year")],
    }))
  )(resultSet.rawData());
}

function getDemographics(selection, resultSet) {
  return _pipe(
    _groupBy(getDimension(selection, "demographic_type_primary")),
    _mapValues((values) =>
      _map(getDimension(selection, "demographic_value_primary"))(values)
    ),
    _mapValues(_uniq),
    // TODO: deal with duke_assocation demographics
    _omit(["null", "duke_association"])
  )(resultSet.rawData());
}

export default useStatistics;
