import { defineMessages } from "react-intl";

const MESSAGES = defineMessages({
  demographics: {
    id: "demographics",
    defaultMessage: "Demographics",
  },
  economy: {
    id: "economy",
    defaultMessage: "Economy",
  },
  education: {
    id: "education",
    defaultMessage: "Education",
  },
  environment: {
    id: "environment",
    defaultMessage: "Environment",
  },
  food_access: {
    id: "food_access",
    defaultMessage: "Food Access",
  },
  health: {
    id: "health",
    defaultMessage: "Health",
  },
  housing: {
    id: "housing",
    defaultMessage: "Housing",
  },
  indices: {
    id: "indices",
    defaultMessage: "Indices",
  },
  infrastructure: {
    id: "infrastructure",
    defaultMessage: "Infrastructure and Amenities",
  },
  year: {
    id: "year",
    defaultMessage: "Year",
  },
});

export default MESSAGES;
