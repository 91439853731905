import { handleGetAuthToken, handleDeleteAuthToken } from "../services";

export const handleInterceptorRequestAPI = (configRequest) => {
  const { accessToken } = handleGetAuthToken();

  configRequest.headers.Authorization = `Bearer ${accessToken}`;

  return configRequest;
};

export const handleInterceptorErrorResponseAPI = async (_, error) => {
  if (error?.code === "ERR_NETWORK") {
    error.message = "Error connecting to server";
  }

  if (error?.response?.data?.detail === "Token has expired") {
    handleDeleteAuthToken();
    window.location.href = "/sign-in";
  }

  return Promise.reject(error);
};
