import { Col, Menu, Row } from "antd";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../Logo";
import { FormattedMessage } from "react-intl";
import { PRIVATE_MENU_ITEMS, URL_CONTACT } from "../../../constants";
import ActionButton from "../../ActionButton";
import "./HomeHeader.css";

function HomeHeader({ handleClickPrivateMenu, isFooter = false }) {
  const location = useLocation();

  return (
    <Row
      justify="space-between"
      style={{
        width: "100%",
        display: "flex",
        padding: "24px 64px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col span={"auto"}>
        <Link to="/">
          <Logo type="primary" isHomePage="true" />
        </Link>
      </Col>
      <Col lg={16} md={14} sm={12} xs={1}>
        <Menu
          className="menu"
          mode="horizontal"
          selectedKeys={[location.pathname]}
          style={{
            textAlign: "center",
            background: "none",
            justifyContent: "center",
          }}
        >
          <Menu.Item
            key="/"
            className={"menu-item active"}
            selectedKeys={[location.pathname]}
          >
            <Link to="/">
              <FormattedMessage description="Home" defaultMessage="Home" />
            </Link>
          </Menu.Item>

          <Menu.Item
            key="/download"
            onClick={() => handleClickPrivateMenu(PRIVATE_MENU_ITEMS.DOWNLOAD)}
            className={"menu-item"}
          >
            <FormattedMessage description="Download link" defaultMessage="Download" />
          </Menu.Item>

          <Menu.Item
            key="/api"
            onClick={() => handleClickPrivateMenu(PRIVATE_MENU_ITEMS.API)}
            className={"menu-item"}
          >
            <FormattedMessage description="API Link" defaultMessage="API" />
          </Menu.Item>

          <Menu.Item
            key="/contact"
            className={"menu-item"}
          >
            <a href={URL_CONTACT}>
              <FormattedMessage description="Contact link" defaultMessage="Contact" />
            </a>
          </Menu.Item>
        </Menu>
      </Col>
      <Col span={"auto"}>
        <ActionButton
          textButton={isFooter ? "Contact Us" : "Go to the Atlas"}
          url={isFooter ? URL_CONTACT : "/atlas"}
        />
      </Col>
    </Row>
  );
}

export default HomeHeader;
