import {
  handleDownloadFile,
  handleFormattedStatisticsQuery,
} from "../../../utils";
import { useCallback, useEffect } from "react";
import { statisticsAPI } from "../../../services";
import { useMapBoxParams } from "../../../store/useMapBoxParams";
import { useDownloadParams } from "../../../store";
import { useNotification } from "../../../hooks";
import { getErrorAPIMessage } from "../../../errors/getErrorAPIMessage";
import { handleFormattedStatisticsMapResponse } from "../../../utils/handleFormattedStatisticsMapResponse";
import { URL_STATISTICS } from "../../../constants";

function useStatisticsV2({
  selection,
  isDownload = false,
  yearsLoading = false,
  demographicsLoading = false,
}) {
  const { handleNotify } = useNotification();

  const { statistics, setStatistics, selectedYear, selectedDemographic } =
    useMapBoxParams(
      ({ statistics, setStatistics, selectedYear, selectedDemographic }) => ({
        statistics,
        setStatistics,
        selectedYear,
        selectedDemographic,
      })
    );

  const { isLoadingCSV, setIsLoadingCSV } = useDownloadParams(
    ({ isLoadingCSV, setIsLoadingCSV }) => ({
      isLoadingCSV,
      setIsLoadingCSV,
    })
  );

  const handleFetchStatistics = useCallback(async ({ listURL }) => {
    const responses = await Promise.allSettled(
      listURL.map((url) => statisticsAPI.get(url))
    );

    const resultData = responses.reduce((acc, response) => {
      if (response.status === "fulfilled") {
        return acc.concat(response.value.data || []);
      } else {
        return acc;
      }
    }, []);

    return resultData;
  }, []);

  const handleGetMapStatistics = useCallback(async () => {
    setStatistics({
      isLoading: true,
      data: [],
      demographics: {},
    });

    const { listURL } = handleFormattedStatisticsQuery({
      level: selection?.level,
      statistic: selection?.statistic,
      unit: selection?.unit,
      countyFilter: selection?.countyFilter,
      selectedYear,
      yearsLoading,
      selectedDemographic,
      demographicsLoading,
    });

    if (!listURL?.length) return;

    try {
      const resultData = await handleFetchStatistics({ listURL });

      if (resultData) {
        const { data } = handleFormattedStatisticsMapResponse({
          resultData,
          category: selection?.category,
        });

        setStatistics({
          isLoading: false,
          data,
        });
      }
    } catch (error) {
      setStatistics({
        isLoading: false,
        data: [],
      });
    }
  }, [
    setStatistics,
    selection?.level,
    selection?.statistic,
    selection?.unit,
    selection?.countyFilter,
    selection?.category,
    selectedYear,
    yearsLoading,
    selectedDemographic,
    demographicsLoading,
    handleFetchStatistics,
  ]);

  const handleGetDownloadStatistics = useCallback(async () => {
    const { listURL } = handleFormattedStatisticsQuery({
      level: selection?.level,
      statistic: selection?.statistic,
      unit: selection?.unit,
      countyFilter: selection?.countyFilter,
      selectedYear: selection?.selectedYears,
      yearsLoading,
      selectedDemographic,
      demographicsLoading,
      isDownload,
    });

    if (!listURL?.length) return;

    try {
      setIsLoadingCSV(true);

      listURL?.forEach((url) => {
        const downloadURL = `${URL_STATISTICS}${url}`;
        handleDownloadFile(downloadURL);
      });

      handleNotify({
        type: "success",
        content: "Successfully downloaded.",
      });
    } catch (error) {
      handleNotify({
        type: "error",
        content: getErrorAPIMessage(error),
      });
    } finally {
      setIsLoadingCSV(false);
    }
  }, [
    demographicsLoading,
    handleNotify,
    isDownload,
    selectedDemographic,
    selection?.countyFilter,
    selection?.level,
    selection?.selectedYears,
    selection?.statistic,
    selection?.unit,
    setIsLoadingCSV,
    yearsLoading,
  ]);

  useEffect(() => {
    !isDownload && handleGetMapStatistics();
  }, [handleGetMapStatistics, isDownload]);

  return {
    statistics,
    selectedYear,
    isLoadingCSV,
    handleGetDownloadStatistics,
  };
}

export default useStatisticsV2;
