import React from "react";
import { Modal, Button, Col, Row, Form } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import colors from "../../colors";
import Input from "../../components/Input";
import Label from "../../components/Label";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks";
import { DATA_STEPS } from "../../constants";
import "./index.css";
import styles from "./signInModal.module.css";
import loginIcon from "../../images/login-icon.svg";

function SignInModal({ showModal, setShowModal }) {
  const [form] = Form.useForm();

  const history = useHistory();

  const { handleLogin, dataStep } = useAuth();

  const handleNavigateToSignUp = () => {
    history.push("/sign-up");
  };

  const handleCancel = () => {
    setShowModal(!showModal);
  };

  return (
    <Modal
      open={showModal}
      onCancel={handleCancel}
      footer={null}
      width={"1080px"}
      height={"620px"}
      closeIcon={<CloseOutlined style={{ color: colors.white }} />}
      wrapClassName={styles.signInModal}
      bodyStyle={{
        width: "1080px",
        height: "620px",
        alignItems: "space-between",
      }}
    >
      <Row
        justify="left"
        align="space-between"
        style={{
          width: "100%",
          padding: "48px",
        }}
      >
        <Col
          style={{
            width: "100%",
            maxWidth: 420,
          }}
        >
          <Row style={{ marginBottom: 32 }}>
            <h1
              style={{
                color: colors.accentDarkMode,
                fontSize: "24px",
                fontWeight: 700,
              }}
            >
              <FormattedMessage
                description={"Sign In"}
                defaultMessage="Sign In"
              />
            </h1>
          </Row>

          <Row>
            <Form
              form={form}
              layout="vertical"
              name="sign-in"
              onFinish={async (values) => {
                await handleLogin(values);
                setShowModal(!showModal);
              }}
              requiredMark={false}
              style={{ width: "100%" }}
            >
              <Form.Item
                label={<Label>Email</Label>}
                name="email"
                style={{ marginBottom: 32 }}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Insert your email" />
              </Form.Item>

              <Form.Item label={<Label>Password</Label>} name="password">
                <Input.Password placeholder="Insert your password" />
              </Form.Item>

              <Row style={{ marginBottom: 32 }}>
                <Button
                  type="link"
                  style={{
                    color: colors.yellowLogin,
                    textDecoration: "underline",
                    padding: 0,
                    fontSize: 14,
                  }}
                  onClick={() => history.push("/forgot-password")}
                >
                  <FormattedMessage
                    description="Forgot my password"
                    defaultMessage="Forgot my password"
                  />
                </Button>
              </Row>

              <Row>
                <Button
                  htmlType="submit"
                  size="large"
                  style={{
                    backgroundColor: colors.dukeBlue,
                    height: 44,
                    borderRadius: 30,
                    color: colors.white,
                    fontSize: 16,
                    fontWeight: 700,
                    border: "1px solid #2A61B4",
                  }}
                  block
                  loading={dataStep === DATA_STEPS.LOADING}
                >
                  <FormattedMessage
                    description="Login"
                    defaultMessage="Login"
                  />
                </Button>
              </Row>
            </Form>
          </Row>

          <Row justify="left" style={{ marginTop: 32 }}>
            <p style={{ fontSize: 14, color: colors.white }}>
              I don’t have an account,{" "}
              <Button
                type="link"
                style={{
                  color: colors.yellowLogin,
                  textDecoration: "underline",
                  fontSize: 14,
                  fontWeight: 400,
                  padding: 2,
                }}
                onClick={handleNavigateToSignUp}
              >
                <FormattedMessage
                  description="sign up"
                  defaultMessage="sign up"
                />
              </Button>
            </p>
          </Row>
        </Col>
        <Col>
          <hr style={{ border: "1px solid #2F3B46", height: "100%" }} />
        </Col>
        <Col
          style={{
            width: "100%",
            maxWidth: 420,
            alignItems: "center",
          }}
        >
          <Row justify="center" style={{ marginBottom: 32 }}>
            <img src={loginIcon} alt="login-icon" />
          </Row>

          <Row></Row>

          <Row justify="center" style={{ marginTop: 32 }}>
            <p
              style={{ fontSize: 20, color: colors.white, textAlign: "center" }}
            >
              To access the downloads, just log in or sign up if you don’t have
              an account yet.
            </p>
            <p
              style={{
                fontSize: 20,
                fontWeight: 700,
                color: colors.white,
                textAlign: "center",
              }}
            >
              We’ll get you started in no time!
            </p>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

export default SignInModal;
