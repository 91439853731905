import { useCallback, useEffect } from "react";
import { useMapBoxParams } from "../../../store";
import { getFormattedMapMetric } from "../../../utils";
import _first from "lodash/fp/first";
import { getErrorAPIMessage } from "../../../errors/getErrorAPIMessage";
import { useNotification } from "../../../hooks";
import { statisticsAPI } from "../../../services";

const useGetDemographics = ({ statistic, unit, level }) => {
  const { handleNotify } = useNotification();

  const {
    setSelectedDemographic,
    setListDemographics,
    demographicsLoading,
    setDemographicsLoading,
  } = useMapBoxParams(
    ({
      setSelectedDemographic,
      setListDemographics,
      demographicsLoading,
      setDemographicsLoading,
    }) => ({
      setSelectedDemographic,
      setListDemographics,
      demographicsLoading,
      setDemographicsLoading,
    })
  );

  const handleGetDemographics = useCallback(async () => {
    if (!statistic?.length || !unit?.length || !level?.length) return;

    setDemographicsLoading(true);
    setListDemographics([]);

    const metric = getFormattedMapMetric(statistic, unit);

    if (!metric?.length) return;

    const URL = `/lists/demographics/metric=${metric}/level=${level}/data.json.gz`;

    try {
      const { data: resultData } = await statisticsAPI.get(URL);

      if (!!resultData?.demographics) {
        const filteredListDemographics = resultData?.demographics?.filter(
          (demographic) =>
            demographic.demographic_type_primary !== "duke_association"
        );

        const lastDemographic = _first(filteredListDemographics);

        setListDemographics(filteredListDemographics);
        setSelectedDemographic(lastDemographic);
      }
    } catch (error) {
      handleNotify({
        type: "error",
        content: getErrorAPIMessage(error),
      });
    } finally {
      setDemographicsLoading(false);
    }
  }, [
    handleNotify,
    level,
    setDemographicsLoading,
    setListDemographics,
    setSelectedDemographic,
    statistic,
    unit,
  ]);

  useEffect(() => {
    handleGetDemographics();
  }, [handleGetDemographics]);

  return { isLoading: demographicsLoading };
};

export default useGetDemographics;
