import { defineMessages } from "react-intl";

const MESSAGES = defineMessages({
  block_group: {
    id: "block_group",
    defaultMessage: "Block Group",
  },
  tract: {
    id: "tract",
    defaultMessage: "Tract",
  },
  alamance: {
    id: "alamance",
    defaultMessage: "Alamance",
  },
  chatham: {
    id: "chatham",
    defaultMessage: "Chatham",
  },
  durham: {
    id: "durham",
    defaultMessage: "Durham",
  },
  franklin: {
    id: "franklin",
    defaultMessage: "Franklin",
  },
  granville: {
    id: "granville",
    defaultMessage: "Granville",
  },
  guilford: {
    id: "guilford",
    defaultMessage: "Guilford",
  },
  orange: {
    id: "orange",
    defaultMessage: "Orange",
  },
  person: {
    id: "person",
    defaultMessage: "Person",
  },
  vance: {
    id: "vance",
    defaultMessage: "Vance",
  },
  wake: {
    id: "wake",
    defaultMessage: "Wake",
  },
});

export default MESSAGES;
