import { Image } from "antd";
import LogoImg from "../../images/logo.svg";
import LogoSecondaryImg from "../../images/logo-secondary.svg";
import HomePageLogo from "../../images/home-page-logo.svg";

function Logo({ type = "primary", isHomePage = false }) {
  return (
    <Image
      src={type === "primary" && !isHomePage ? LogoImg : !isHomePage ? LogoSecondaryImg : HomePageLogo}
      preview={false}
      alt="Duke CTSI Logo"
    />
  );
}

export default Logo;
