import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { DownloadCsvModal } from "../../DownloadModal/DownloadModal";
import { PRIVATE_MENU_ITEMS, URL_API } from "../../../constants";
import {
  handleGetAuthToken,
  handleSetPrivateMenuItemPath,
} from "../../../services";
import HomeHeader from "./HomeHeader";
import ChildrenHeader from "./ChildrenHeader";
import SignInModal from "../../../pages/SignIn/signInModal";

function AppHeader({ isHomePage = true, isFooter = false }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const history = useHistory();

  const showDownloadModal = useCallback(() => {
    setIsModalVisible((prev) => !prev);
  }, []);

  const showLoginModalCallback = useCallback(() => {
    setShowLoginModal((prev) => !prev);
  }, []);

  const handleClickPrivateMenu = useCallback(
    (menuItem) => {
      if (!menuItem) return;

      const { accessToken } = handleGetAuthToken();

      if (!accessToken) {
        handleSetPrivateMenuItemPath(menuItem);
        showLoginModalCallback();

        return;
      }

      switch (menuItem) {
        case PRIVATE_MENU_ITEMS.API:
          window.open(`${URL_API}/api/v1/docs`);
          break;
        case PRIVATE_MENU_ITEMS.DOWNLOAD:
          showDownloadModal();
          break;
        default:
          break;
      }
    },
    [history, showDownloadModal]
  );

  return (
    <nav
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {isHomePage ? (
        <HomeHeader
          handleClickPrivateMenu={handleClickPrivateMenu}
          isFooter={isFooter}
        />
      ) : (
        <ChildrenHeader handleClickPrivateMenu={handleClickPrivateMenu} />
      )}

      {isModalVisible && (
        <DownloadCsvModal
          setVisible={showDownloadModal}
          visible={isModalVisible}
        />
      )}

      {showLoginModal && (
        <SignInModal
          showModal={showLoginModal}
          setShowModal={showLoginModalCallback}
        />
      )}
    </nav>
  );
}

export default AppHeader;
