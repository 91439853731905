const DUKE_COLORS = {
  navyBlue: "#012169",
  royalBlue: "#00539B",
  copper: "#C84E00",
  persimmon: "#E89923",
  dandelion: "#FFD960",
  piedmont: "#A1B70D",
  eno: "#339898",
  magnolia: "#1D6363",
  prussianBlue: "#005587",
  shaleBlue: "#0577B1",
  ironweed: "#993399",
  hatteras: "#E2E6ED",
  whisperGray: "#F3F2F1",
  gingerBeer: "#FCF7E5",
  dogwood: "#988675",
  shackleford: "#DAD0C6",
  castIron: "#262626",
  graphite: "#666666",
  granite: "#B5B5B5",
  limestone: "#E5E5E5",
  white: "#FFFFFF",
  dukeBlue: "#00529B",
  orange: "#DF7725",
  colorBar4: "#F8C630",
  colorBar5: "#00D9C0",
  darkBlue: "#020A0D",
  accentDarkMode: "#91E9E4",
  secondaryBlue: "#4C80CE",
  titleBaseColor: "#F8FAFF",
  textColor: "#E0E4F2",
  yellowLogin: "#F8C35F",
  gray: {
    100: "#F4F7FA",
    200: "#CFCFCF",
    300: "#9A9A9A",
    400: "#717171",
    500: "#4A4A4A",
    600: "#403E40",
    700: "#5A6688",
    900: "#161616",
  },
  black: {
    800: "#0E0E0E",
    900: "#000000",
  },
  blue: {
    400: "#0F2B43",
    500: "#021950",
  },
  red: {
    500: "#D61B1B",
  },
};

module.exports = DUKE_COLORS;
