import cubejs from "@cubejs-client/core";
import { IntlProvider } from "react-intl";

import ReactDOM from "react-dom";
import React from "react";

import App from "./App";
import TopLevelErrorBoundary from "./errors/TopLevelErrorBoundary";
import { CubeQueryProvider } from "./cube";
import getMessages from "./i18n/locale";
import reportWebVitals from "./reportWebVitals";

bootstrapApp();

function bootstrapApp() {
  const locale = getLocale();
  const messages = getMessages(locale);
  ReactDOM.render(
    <IntlProvider
      locale={locale}
      messages={messages}
      defaultRichTextElements={{
        u: (value) => <u>{value}</u>,
      }}
    >
      <TopLevelErrorBoundary>
        <CubeQueryProvider cubejsApi={getCubejsApi()}>
          <App />
        </CubeQueryProvider>
      </TopLevelErrorBoundary>
    </IntlProvider>,
    document.getElementById("root")
  );
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

function getLocale() {
  let locale = navigator.language || "en-US";
  locale = window.location.search.replace("?locale=", "") || locale;
  return locale;
}

function getCubejsApi() {
  return cubejs(window._env_.REACT_APP_CUBEJS_TOKEN, {
    apiUrl: `${window._env_.REACT_APP_CUBEJS_API}/cubejs-api/v1`,
  });
}
