import React from "react";
import { Button, Menu, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import _entries from "lodash/fp/entries";
import _groupBy from "lodash/fp/groupBy";
import _map from "lodash/fp/map";
import _mapValues from "lodash/fp/mapValues";
import _pipe from "lodash/fp/pipe";
import _remove from "lodash/fp/remove";
import _sortBy from "lodash/fp/sortBy";
import _uniqBy from "lodash/fp/uniqBy";

import { useMeasures } from "../../cube";
import useHumanize from "../../humanize";
import useDescription from "../../descriptions";

import { selectionToRoute } from "./route";

import styles from "./StatisticsMenu.module.css";
import { REMOVED_STATISTICS } from "../../constants";

const { SubMenu } = Menu;

function StatisticsMenu({ selection }) {
  const measures = _remove((measure) => measure.category === null)(
    useMeasures()
  );

  const humanize = useHumanize();

  const categories = getCategories(measures);

  return (
    <Menu mode="inline" style={{ border: "none" }}>
      {_map(([category, statisticGroups]) => (
        <SubMenu key={category} title={humanize(category)}>
          {_map(([statisticGroup, statistics]) => {
            statisticGroup = parseNullKey(statisticGroup);

            const humanizedStatisticGroup = humanize(
              Object.keys(statistics)[0]
            );

            if (REMOVED_STATISTICS.includes(humanizedStatisticGroup)) return;

            return addSubMenu({
              shouldAdd: statisticGroup !== null,
              shouldAddPopover: statisticGroup === "cdc",
              key: statisticGroup,
              title: statisticGroup !== null ? humanize(statisticGroup) : null,
              children: _map(({ statistic }) => {
                const humanizedStatistic = humanize(statistic);

                if (REMOVED_STATISTICS.includes(humanizedStatistic)) return;

                return (
                  <Menu.Item
                    key={`${category}-${statistic}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    disabled={
                      !measures.some(
                        (m) =>
                          m.statistic === statistic &&
                          m.level === selection.level
                      )
                    }
                    className={styles.menuItem}
                  >
                    <Link
                      to={selectionToRoute({
                        category,
                        statistic,
                      })}
                      title={humanizedStatistic}
                      style={{
                        maxWidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {humanizedStatistic}
                    </Link>

                    <Popover
                      placement="right"
                      title={humanizedStatistic}
                      content={<Description statistic={statistic} />}
                      trigger="click"
                      overlayStyle={{
                        width: 300,
                        height: 600,
                        overflowY: "auto",
                      }}
                    >
                      <Button
                        type="default"
                        shape="circle"
                        onClick={(e) => e.stopPropagation()}
                        style={{ border: "none", background: "none" }}
                      >
                        <InfoCircleOutlined style={{ margin: 0 }} />
                      </Button>
                    </Popover>
                  </Menu.Item>
                );
              })(statistics),
            });
          })(_entries(statisticGroups))}
        </SubMenu>
      ))(_entries(categories))}
    </Menu>
  );
}

export function getCategories(measures) {
  return _pipe(
    _sortBy(["category", "statistic"]),
    // _remove((measure) => measure.category === "demographics"),
    _groupBy("category"),
    _mapValues(
      _pipe(
        _groupBy((measure) => {
          if (
            measure.category === "health" ||
            measure.statistic.includes("bb_") ||
            measure.statistic.includes("et_") ||
            measure.statistic.includes("hi_")
          ) {
            return measure.statistic.slice(0, measure.statistic.indexOf("_"));
          } else {
            return null;
          }
        }),
        _mapValues(
          _pipe(
            _groupBy("statistic"),
            _mapValues(_pipe(_uniqBy(["category", "statistic"]), one))
          )
        )
      )
    )
  )(measures);
}

function one(items) {
  if (items.length !== 1) {
    throw new Error(
      `More than one item in items: ${_map(JSON.stringify)(items)}`
    );
  }
  return items[0];
}

function addSubMenu({ shouldAdd, shouldAddPopover, key, title, children }) {
  if (shouldAdd) {
    return (
      <SubMenu
        key={key}
        title={
          <CustomSubMenu
            shouldAddPopover={shouldAddPopover}
            statisticGroup={key}
            title={title}
          />
        }
      >
        {children}
      </SubMenu>
    );
  } else {
    return children;
  }
}

const CustomSubMenu = ({ shouldAddPopover, statisticGroup, title }) => {
  return (
    <article>
      <span>{title}</span>

      {shouldAddPopover && (
        <Popover
          placement="right"
          title={title}
          content={<Description statistic={statisticGroup} />}
          trigger="click"
          overlayStyle={{
            width: 300,
            height: 600,
            overflowY: "auto",
          }}
        >
          <Button
            type="default"
            shape="circle"
            onClick={(e) => e.stopPropagation()}
            style={{ border: "none", background: "none" }}
          >
            <InfoCircleOutlined style={{ margin: 0 }} />
          </Button>
        </Popover>
      )}
    </article>
  );
};

function parseNullKey(key) {
  if (key === "null") {
    return null;
  } else {
    return key;
  }
}

function Description({ statistic }) {
  return useDescription(statistic);
}

export default StatisticsMenu;
