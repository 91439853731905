import { Input } from "antd";
import colors from "../../colors";

function InputDefault({ placeholder, ...props }) {
  return (
    <Input
      placeholder={placeholder}
      size="large"
      style={{
        backgroundColor: "#0F2B43",
        border: "1px solid #00529B",
        padding: 16,
        color: colors.gray[300],
        height: 54,
      }}
      {...props}
    />
  );
}

export default InputDefault;
