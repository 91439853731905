import { defineMessages } from "react-intl";

const MESSAGES = defineMessages({
  1: {
    id: "foodAccessYes",
    defaultMessage: "Yes",
  },
  0: {
    id: "foodAccessNo",
    defaultMessage: "No",
  },
});

export default MESSAGES;
