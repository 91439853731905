import { defineMessages } from "react-intl";

const MESSAGES = defineMessages({
  // generic other
  other: {
    id: "other",
    defaultMessage: "Other",
  },
  // age
  age: {
    id: "age",
    defaultMessage: "Age",
  },
  gt_1: {
    id: "gt_1",
    defaultMessage: "1 and older",
  },
  lt_5: {
    id: "lt_5",
    defaultMessage: "5 and younger",
  },
  "5_to_9": {
    id: "5_to_9",
    defaultMessage: "5 to 9",
  },
  "10_to_14": {
    id: "10_to_14",
    defaultMessage: "10 to 14",
  },
  "15_to_17": {
    id: "15_to_17",
    defaultMessage: "15 to 17",
  },
  "18_to_29": {
    id: "18_to_29",
    defaultMessage: "18 to 29",
  },
  "45_to_64": {
    id: "45_to_64",
    defaultMessage: "45 to 64",
  },
  "50_to_64": {
    id: "50_to_64",
    defaultMessage: "50 to 64",
  },
  gte_65: {
    id: "gte_65",
    defaultMessage: "65 and older",
  },
  "30_to_44": {
    id: "30_to_44",
    defaultMessage: "30 to 44",
  },
  gte_80: {
    id: "gte_80",
    defaultMessage: "80 and older",
  },
  "18_to_25": {
    id: "18_to_25",
    defaultMessage: "18 to 25",
  },
  "26_to_34": {
    id: "26_to_34",
    defaultMessage: "26 to 34",
  },
  "35_to_40": {
    id: "35_to_40",
    defaultMessage: "35 to 40",
  },
  gte_41: {
    id: "gte_41",
    defaultMessage: "Older than 40",
  },
  "30_to_64": {
    id: "30_to_64",
    defaultMessage: "30 to 64",
  },
  "65_to_75": {
    id: "65_to_75",
    defaultMessage: "65 to 75",
  },
  gte_76: {
    id: "gte_76",
    defaultMessage: "Older than 75",
  },
  // race
  race: {
    id: "race",
    defaultMessage: "Race",
  },
  asian: {
    id: "asian",
    defaultMessage: "Asian",
  },
  black: {
    id: "black",
    defaultMessage: "Black",
  },
  white: {
    id: "white",
    defaultMessage: "White",
  },
  // ethnicity
  ethnicity: {
    id: "ethnicity",
    defaultMessage: "Ethnicity",
  },
  foreign_born: {
    id: "foreign_born",
    defaultMessage: "Foreign Born",
  },

  hispanic: {
    id: "hispanic",
    defaultMessage: "Hispanic",
  },
  non_hispanic: {
    id: "non_hispanic",
    defaultMessage: "Non Hispanic",
  },
  // sex
  sex: {
    id: "sex",
    defaultMessage: "Sex",
  },
  male: {
    id: "male",
    defaultMessage: "Male",
  },
  female: {
    id: "female",
    defaultMessage: "Female",
  },
  // insurance
  insurance: {
    id: "insurance",
    defaultMessage: "Insurance",
  },
  private: {
    id: "private",
    defaultMessage: "Private",
  },
  medicare: {
    id: "medicare",
    defaultMessage: "Medicare",
  },
  medicaid: {
    id: "medicaid",
    defaultMessage: "Medicaid",
  },
});

export default MESSAGES;
