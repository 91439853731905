import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

const HomePage = lazy(() => import("../pages/HomePage"));
const StatisticPage = lazy(() => import("../pages/StatisticPage"));
const Error404Page = lazy(() => import("../pages/Error404Page"));
const SignInPage = lazy(() => import("../pages/SignIn"));
const SignUpPage = lazy(() => import("../pages/SignUp"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));

function Routes() {
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/atlas">
          <StatisticPage />
        </Route>
        <Route path="/sign-in" exact>
          <SignInPage />
        </Route>
        <Route path="/sign-up" exact>
          <SignUpPage />
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPassword />
        </Route>
        <Route>
          <Error404Page />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default Routes;
