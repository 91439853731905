import { useEffect } from "react";

import _isEmpty from "lodash/fp/isEmpty";
import _isNil from "lodash/fp/isNil";
import _map from "lodash/fp/map";
import _pick from "lodash/fp/pick";
import _uniq from "lodash/fp/uniq";

import { useMeasures } from "../../../cube";

function useLevels(selection) {
  const measures = useMeasures(_pick(["category", "statistic"])(selection));
  return _uniq(_map("level")(measures));
}

function useEnsureSelectedLevelEffect(selection, dispatch) {
  const levels = useLevels(selection);
  useEffect(() => {
    const noSelectedLevel = !_isEmpty(levels) && _isNil(selection.level);
    const invalidSelectedLevel =
      !_isEmpty(levels) &&
      !_isNil(selection.level) &&
      !levels.includes(selection.level);
    const couldBeBlockGroup =
      levels.includes("block_group") && selection.level === "tract";
    if (noSelectedLevel || invalidSelectedLevel || couldBeBlockGroup) {
      dispatch({
        type: "select-level",
        target: getDefaultLevel(levels),
      });
    }
  }, [dispatch, selection.level, levels]);
}

function getDefaultLevel(levels) {
  if (levels.includes("block_group")) {
    return "block_group";
  } else {
    return levels[0];
  }
}

export { useLevels, useEnsureSelectedLevelEffect };
