import { message } from "antd";
import React, { useCallback } from "react";

export const NotificationContext = React.createContext({
  handleNotify: ({ type, content }) => {},
});

export const NotificationProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const handleNotify = useCallback(
    ({ type, content }) => {
      messageApi.open({
        type,
        content,
      });
    },
    [messageApi]
  );

  return (
    <NotificationContext.Provider
      value={{
        handleNotify,
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
