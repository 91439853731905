import Axios from "axios";
import { URL_API } from "../constants/URL";
import {
  handleInterceptorRequestAPI,
  handleInterceptorErrorResponseAPI,
} from "../utils";

export const api = Axios.create({
  baseURL: URL_API,
});

api.interceptors.request.use(async (config) => {
  return handleInterceptorRequestAPI(config);
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return await handleInterceptorErrorResponseAPI(api, error);
  }
);
