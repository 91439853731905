import { useCallback } from "react";
import {
  createAccount,
  login,
  resetPassword,
  sendPasswordRecoveryCode,
} from "../services/api";
import { DATA_STEPS, PRIVATE_MENU_ITEMS, URL_API } from "../constants";
import { useState } from "react";
import { handleGetPrivateMenuItemPath, handleSetAuthToken } from "../services";
import { useHistory } from "react-router-dom";
import { useNotification } from "../hooks";

export const useAuth = () => {
  const [dataStep, setDataStep] = useState(DATA_STEPS.EMPTY);
  const { handleNotify } = useNotification();

  const history = useHistory();

  const handleLogin = useCallback(
    async ({ email, password }) => {
      setDataStep(DATA_STEPS.LOADING);

      try {
        const { accessToken } = await login({ email, password });

        if (accessToken) {
          setDataStep(DATA_STEPS.SUCCESS);
          handleSetAuthToken(accessToken);

          handleNotify({
            type: "success",
            content: "Successfully logged in.",
          });

          const { privateMenuPath } = handleGetPrivateMenuItemPath();

          if (privateMenuPath === PRIVATE_MENU_ITEMS.API) {
            window.open(`${URL_API}/api/v1/docs`);
          }

          history.push("/", { privateMenuPath });
        }
      } catch (error) {
        setDataStep(DATA_STEPS.ERROR);

        handleNotify({
          type: "error",
          content: error?.message,
        });
      }
    },
    [handleNotify, history]
  );

  const handleCreateAccount = useCallback(
    async ({
      name,
      email,
      password,
      company,
      employerCategory,
      employerPosition,
      intendToUse,
      acceptedTerms,
    }) => {
      if (!acceptedTerms) {
        handleNotify({
          type: "warning",
          content: "Please accept terms and conditions.",
        });

        return;
      }

      setDataStep(DATA_STEPS.LOADING);

      try {
        await createAccount({
          name,
          email,
          password,
          company,
          employerCategory,
          employerPosition,
          intendToUse,
          acceptedTerms,
        });

        handleNotify({
          type: "success",
          content: "Account created successfully.",
        });

        history.push("/sign-in");
      } catch (error) {
        setDataStep(DATA_STEPS.ERROR);

        handleNotify({
          type: "error",
          content: error?.message,
        });
      }
    },
    [handleNotify, history]
  );

  const handleSendPasswordRecoveryCode = useCallback(
    async (email) => {
      setDataStep(DATA_STEPS.LOADING);
      try {
        await sendPasswordRecoveryCode(email);

        handleNotify({
          type: "success",
          content: "Code successfully sent to your email",
        });

        setDataStep(DATA_STEPS.VALIDATE_CODE);
      } catch (error) {
        setDataStep(DATA_STEPS.ERROR);

        handleNotify({
          type: "error",
          content: error?.message,
        });
      }
    },
    [handleNotify]
  );

  const handleValidateCode = useCallback(
    async (code) => {
      try {
        if (!!code?.length) {
          setDataStep(DATA_STEPS.PENDING);
        }
      } catch (error) {
        setDataStep(DATA_STEPS.ERROR);

        handleNotify({
          type: "error",
          content: error?.message,
        });
      }
    },
    [handleNotify]
  );

  const handleResetPassword = useCallback(
    async ({ email, newPassword, code }) => {
      setDataStep(DATA_STEPS.LOADING);

      try {
        await resetPassword({ email, code, newPassword });

        handleNotify({
          type: "success",
          content: "Password changed successfully",
        });

        setDataStep(DATA_STEPS.SUCCESS);

        history.push("/sign-in");
      } catch (error) {
        handleNotify({
          type: "error",
          content: error?.message,
        });

        if (error?.message === "Invalid recovery code") {
          setDataStep(DATA_STEPS.VALIDATE_CODE);

          return;
        }

        setDataStep(DATA_STEPS.ERROR);
      }
    },
    [handleNotify, history]
  );

  return {
    handleLogin,
    handleCreateAccount,
    handleSendPasswordRecoveryCode,
    handleValidateCode,
    handleResetPassword,
    dataStep,
  };
};
