import { ACCESS_TOKEN } from "../../constants";

const handleGetAuthToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  return {
    accessToken,
  };
};

const handleSetAuthToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

const handleDeleteAuthToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export { handleGetAuthToken, handleSetAuthToken, handleDeleteAuthToken };
