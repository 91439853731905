import _last from "lodash/fp/last";
import _isEmpty from "lodash/fp/isEmpty";
import _isNil from "lodash/fp/isNil";
import { useEffect } from "react";

function useEnsureSelectedYearEffect(selection, dispatch, years, yearsLoading) {
  useEffect(() => {
    if (!!yearsLoading) {
      selection.year = null;
      return;
    }

    const noSelectedYear = !_isEmpty(years) && _isNil(selection.year);
    const invalidSelectedYear =
      !_isEmpty(years) &&
      !_isNil(selection.year) &&
      !years.includes(selection.year);
    if (noSelectedYear || invalidSelectedYear) {
      dispatch({
        type: "select-year",
        target: _last(years),
      });
    }
  }, [dispatch, selection, selection.year, years, yearsLoading]);
}

export default useEnsureSelectedYearEffect;
