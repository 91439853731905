import colors from "../../colors";

function Label({ children, isOptional = false, ...props }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <span
        style={{
          color: colors.white,
          fontSize: 16,
          fontWeight: 500,
        }}
        {...props}
      >
        {children}
      </span>
      {isOptional && (
        <span style={{ color: colors.white, fontSize: 14, fontWeight: 400 }}>
          Optional
        </span>
      )}
    </div>
  );
}

export default Label;
