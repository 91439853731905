import Axios from "axios";
import { URL_BOUNDERY } from "../constants";
import { handleInterceptorRequestBounderyAPI } from "../utils";

export const bouderyApi = Axios.create({
  baseURL: URL_BOUNDERY,
});

bouderyApi.interceptors.request.use(async (config) => {
  return handleInterceptorRequestBounderyAPI(config);
});
