import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Header from "./components/Header";
import ResponsiveLayout from "./components/ResponsiveLayout";
import Routes from "./routes";
import { NotificationProvider } from "./context/notificationContext";
import { appInsights } from "./services/azure";

function App() {
  useEffect(() => {
    appInsights.loadAppInsights();
  }, []);

  return (
    <NotificationProvider>
      <Router>
        <ResponsiveLayout header={<Header />} content={<Routes />} />
      </Router>
    </NotificationProvider>
  );
}

export default App;
