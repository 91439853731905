import { useEffect, useState } from "react";

const BASE_SCALE = 100;
const MAX_RETRIES = 5;
const JITTER_SCALE = 100;

/**
 * Schedule an effect which calls `retry()` if `error` is truthy. Use
 * exponential backoff with jitter to delay subsequent retries. If after
 * `MAX_RETRIES`, `error` is still truthy, give up and throw `error`.
 */
function useRetryEffect(error, retry) {
  const [errorCount, setErrorCount] = useState(0);

  useEffect(() => {
    let disposed = false;
    (async () => {
      if (error) {
        const waitIntervalMs =
          Math.pow(2, errorCount) * BASE_SCALE + Math.random() * JITTER_SCALE;
        await sleep(waitIntervalMs);
        if (disposed) return;
        if (errorCount >= MAX_RETRIES) {
          throw error;
        } else {
          retry();
          setErrorCount((errorCount) => errorCount + 1);
        }
      }
    })();
    return () => (disposed = true);
  }, [error, retry, errorCount]);
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default useRetryEffect;
