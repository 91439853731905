import { PRIVATE_MENU_ITEM_PATH } from "../../constants";

const handleGetPrivateMenuItemPath = () => {
  const privateMenuPath = localStorage.getItem(PRIVATE_MENU_ITEM_PATH);

  return {
    privateMenuPath,
  };
};

const handleSetPrivateMenuItemPath = (accessToken) => {
  localStorage.setItem(PRIVATE_MENU_ITEM_PATH, accessToken);
};

const handleDeletePrivateMenuItemPath = () => {
  localStorage.removeItem(PRIVATE_MENU_ITEM_PATH);
};

export {
  handleGetPrivateMenuItemPath,
  handleSetPrivateMenuItemPath,
  handleDeletePrivateMenuItemPath,
};
