function parseDemographicSelectValue(selectValue) {
  const [demographic_type_primary, demographic_value_primary] =
    selectValue.split(":");
  return { demographic_type_primary, demographic_value_primary };
}

function toDemographicSelectValue({
  demographic_type_primary,
  demographic_value_primary,
}) {
  return `${demographic_type_primary}:${demographic_value_primary}`;
}

export { parseDemographicSelectValue, toDemographicSelectValue };
