import { useCallback, useEffect, useState } from "react";
import _isEqual from "lodash/fp/isEqual";

import { bouderyApi } from "../../../services";

import { DATA_STEPS, STATES } from "../../../constants";

const CACHED_URLS = [];

function useBoundaries(selection) {
  const [dataStep, setDataStep] = useState(DATA_STEPS.EMPTY);
  const [boundaries, setBoundaries] = useState({
    data: {},
    isSameBoundaries: false,
  });

  const handleGetBounderies = useCallback(async () => {
    if (!selection.level | !selection.year) return;

    setDataStep(DATA_STEPS.LOADING);

    const selectedYear = selection?.year <= 2019 ? "2019" : "2022";

    const formattedLevel = selection.level === "tract" ? "tract" : "bg";

    const selectedCountries =
      selection?.countyFilter.length === 1 && selection?.countyFilter[0] === ""
        ? STATES.map((c) => c.zipcode).filter((c) => c !== "")
        : selection?.countyFilter;

    const newUrls = selectedCountries
      .map((county) => {
        const url = `/tl_${selectedYear}_${county}_${formattedLevel}.geojson.gz`;

        if (CACHED_URLS.includes(url)) return null;
        CACHED_URLS.push(url);

        return url;
      })
      .filter(Boolean);

    try {
      const responses = await Promise.all(
        newUrls.map((url) => bouderyApi(url))
      );

      const formattedBoundaries = responses.reduce((accumulator, response) => {
        response?.data?.features?.forEach((feature) => {
          accumulator[feature.properties.GEOID] = {
            coordinates: feature.geometry.coordinates,
            type: feature.geometry.type,
          };
        });
        return accumulator;
      }, {});

      setBoundaries((prev) => ({
        data: { ...prev.data, ...formattedBoundaries },
        isSameBoundaries: _isEqual(prev.data, formattedBoundaries),
      }));

      setDataStep(DATA_STEPS.SUCCESS);
    } catch (error) {
      setDataStep(DATA_STEPS.ERROR);
    }
  }, [selection?.countyFilter, selection.level, selection.year]);

  useEffect(() => {
    handleGetBounderies();
  }, [handleGetBounderies]);

  return {
    boundaryLookup: boundaries?.data,
    isLoading:
      dataStep === DATA_STEPS.LOADING ||
      (dataStep !== DATA_STEPS.EMPTY && !Object.keys(boundaries?.data).length),
    hasBoundaryData:
      dataStep === DATA_STEPS.SUCCESS && !boundaries?.isSameBoundaries,
  };
}

export default useBoundaries;
