import { useContext } from "react";
import { NotificationContext } from "../context/notificationContext";

export const useNotification = () => {
  const { handleNotify } = useContext(NotificationContext);

  return {
    handleNotify,
  };
};
