import { STATES } from "../constants";
import { getFormattedMapMetric } from "./getFormattedMapMetrics";

export const handleFormattedStatisticsQuery = ({
  level,
  statistic,
  unit,
  countyFilter,
  selectedYear,
  yearsLoading,
  selectedDemographic,
  demographicsLoading,
  isDownload = false,
}) => {
  if (
    yearsLoading ||
    demographicsLoading ||
    !selectedYear?.length ||
    !countyFilter?.length ||
    !statistic?.length ||
    !unit?.length ||
    !level?.length
  )
    return {
      newStatisticsURL: null,
    };

  const selectedCountries =
    countyFilter.length === 1 && countyFilter[0] === ""
      ? STATES.map((c) => c.zipcode).filter((c) => c !== "")
      : countyFilter;

  const metric = getFormattedMapMetric(statistic, unit);

  const formattedDemographicType = !selectedDemographic
    ?.demographic_type_primary?.length
    ? "not_defined"
    : selectedDemographic?.demographic_type_primary;

  const formattedDemographicValue = !selectedDemographic
    ?.demographic_value_primary?.length
    ? "not_defined"
    : selectedDemographic?.demographic_value_primary;

  const suffixURL = isDownload ? "data.csv" : "data.json.gz";

  if (
    !metric?.length ||
    !formattedDemographicType?.length ||
    !formattedDemographicValue?.length
  )
    return {
      newStatisticsURL: null,
    };

  const listURL = [];

  for (const county of selectedCountries) {
    const newStatisticsURL = `/year=${selectedYear}/metric=${metric}/level=${level}/state=${county}/demographic_type_primary=${formattedDemographicType}/demographic_value_primary=${formattedDemographicValue}/${suffixURL}`;

    listURL.push(newStatisticsURL);
  }

  return {
    listURL,
  };
};
