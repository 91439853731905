import { useCallback, useEffect } from "react";
import { useMapBoxParams } from "../../../store";
import { getFormattedMapMetric } from "../../../utils";
import _last from "lodash/fp/last";
import { getErrorAPIMessage } from "../../../errors/getErrorAPIMessage";
import { useNotification } from "../../../hooks";
import { statisticsAPI } from "../../../services";

const useGetFullYears = ({ statistic, unit, level }) => {
  const { handleNotify } = useNotification();

  const {
    setSelectedYear,
    listYears,
    setListYears,
    yearsLoading,
    setYearsLoading,
  } = useMapBoxParams(
    ({
      setSelectedYear,
      listYears,
      setListYears,
      yearsLoading,
      setYearsLoading,
    }) => ({
      setSelectedYear,
      listYears,
      setListYears,
      yearsLoading,
      setYearsLoading,
    })
  );

  const handleGetFullYears = useCallback(async () => {
    if (!statistic?.length || !unit?.length || !level?.length) return;

    setYearsLoading(true);

    const metric = getFormattedMapMetric(statistic, unit);

    if (!metric?.length) return;

    const URL = `/lists/years/metric=${metric}/level=${level}/data.json.gz`;

    try {
      const { data: resultData } = await statisticsAPI.get(URL);

      if (!!resultData?.years) {
        const lastYear = _last(resultData?.years);

        setListYears(resultData?.years?.sort((a, b) => a - b));
        setSelectedYear([lastYear]);
      }
    } catch (error) {
      handleNotify({
        type: "error",
        content: getErrorAPIMessage(error),
      });
    } finally {
      setYearsLoading(false);
    }
  }, [
    handleNotify,
    level,
    setListYears,
    setSelectedYear,
    setYearsLoading,
    statistic,
    unit,
  ]);

  useEffect(() => {
    handleGetFullYears();
  }, [handleGetFullYears]);

  return { listYears, isLoading: yearsLoading };
};

export default useGetFullYears;
