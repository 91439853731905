import { getErrorAPIMessage } from "../../errors/getErrorAPIMessage";
import { api } from "../axios";

export const sendPasswordRecoveryCode = async (email) => {
  try {
    await api.post("/auth/forgot-password-code", {
      email,
    });
  } catch (error) {
    throw new Error(getErrorAPIMessage(error));
  }
};
