import { useState } from "react";
import colors from "../../colors";
import { useRef } from "react";

function InputCode({ code, setCode, lengthCode = 5, ...props }) {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null);

  const codeLength = new Array(lengthCode).fill(0);

  const arrCode = code?.toString().split("");
  const currentDigit = (arrCode && arrCode?.length) || 0;

  const handleActiveInputCode = () => {
    setIsFocused(true);
    ref?.current?.focus();
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const getButtonDigit = (_, idx) => {
    const currentDigitIsOnFocus = currentDigit === idx && isFocused;

    return (
      <div
        key={idx}
        onClick={handleActiveInputCode}
        style={{
          backgroundColor: colors.blue[400],
          borderWidth: 1,
          borderColor: colors.dukeBlue,
          borderStyle: "solid",
          borderRadius: 4,
          width: 65,
          height: 120,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "text",
          boxShadow: currentDigitIsOnFocus
            ? "0 0 0 2px rgba(24,144,255,0.2)"
            : "none",
        }}
      >
        <p
          style={{
            fontSize: 48,
            fontWeight: 700,
            color: colors.white,
            margin: 0,
            textTransform: "uppercase",
          }}
        >
          {arrCode ? arrCode[idx] : ""}
        </p>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        {codeLength?.map(getButtonDigit)}
      </div>
      <input
        ref={ref}
        value={!!code?.length ? code : ""}
        onChange={setCode}
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        style={{ opacity: 0, width: 0, height: 0, position: "absolute" }}
        maxLength={lengthCode}
        {...props}
      />
    </>
  );
}

export default InputCode;
