import { useEffect } from "react";

import _filter from "lodash/fp/filter";
import _isEmpty from "lodash/fp/isEmpty";
import _isNil from "lodash/fp/isNil";
import _map from "lodash/fp/map";
import _pick from "lodash/fp/pick";
import _pipe from "lodash/fp/pipe";
import _uniq from "lodash/fp/uniq";

import { useMeasures } from "../../../cube";

function useUnits(selection) {
  const measures = useMeasures(
    _pick(["category", "statistic", "level"])(selection)
  );
  return _pipe(
    _map("unit"),
    _uniq,
    _filter((unit) => !unit.endsWith("quartile"))
  )(measures);
}

function useEnsureSelectedUnitEffect(selection, dispatch) {
  const units = useUnits(selection);
  useEffect(() => {
    const noSelectedUnit = !_isEmpty(units) && _isNil(selection.unit);
    const invalidSelectedUnit =
      !_isEmpty(units) &&
      !_isNil(selection.unit) &&
      !units.includes(selection.unit);
    if (noSelectedUnit || invalidSelectedUnit) {
      dispatch({
        type: "select-unit",
        target: getDefaultUnit(units),
      });
    }
  }, [dispatch, selection.unit, units]);
}

function getDefaultUnit(units) {
  if (units.includes("percent")) {
    return "percent";
  } else {
    return units[0];
  }
}

export { useUnits, useEnsureSelectedUnitEffect };
