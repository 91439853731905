export const STATES = [
  { zipcode: "", name: "All States" },
  { zipcode: "01", name: "Alabama" },
  { zipcode: "02", name: "Alaska" },
  { zipcode: "04", name: "Arizona" },
  { zipcode: "05", name: "Arkansas" },
  { zipcode: "06", name: "California" },
  { zipcode: "08", name: "Colorado" },
  { zipcode: "09", name: "Connecticut" },
  { zipcode: "10", name: "Delaware" },
  { zipcode: "11", name: "District Of Columbia" },
  { zipcode: "12", name: "Florida" },
  { zipcode: "13", name: "Georgia" },
  { zipcode: "15", name: "Hawaii" },
  { zipcode: "16", name: "Idaho" },
  { zipcode: "17", name: "Illinois" },
  { zipcode: "18", name: "Indiana" },
  { zipcode: "19", name: "Iowa" },
  { zipcode: "20", name: "Kansas" },
  { zipcode: "21", name: "Kentucky" },
  { zipcode: "22", name: "Louisiana" },
  { zipcode: "23", name: "Maine" },
  { zipcode: "24", name: "Maryland" },
  { zipcode: "25", name: "Massachusetts" },
  { zipcode: "26", name: "Michigan" },
  { zipcode: "27", name: "Minnesota" },
  { zipcode: "28", name: "Mississippi" },
  { zipcode: "29", name: "Missouri" },
  { zipcode: "30", name: "Montana" },
  { zipcode: "31", name: "Nebraska" },
  { zipcode: "32", name: "Nevada" },
  { zipcode: "33", name: "New Hampshire" },
  { zipcode: "34", name: "New Jersey" },
  { zipcode: "35", name: "New Mexico" },
  { zipcode: "36", name: "New York" },
  { zipcode: "37", name: "North Carolina" },
  { zipcode: "38", name: "North Dakota" },
  { zipcode: "39", name: "Ohio" },
  { zipcode: "40", name: "Oklahoma" },
  { zipcode: "41", name: "Oregon" },
  { zipcode: "42", name: "Pennsylvania" },
  { zipcode: "44", name: "Rhode Island" },
  { zipcode: "45", name: "South Carolina" },
  { zipcode: "46", name: "South Dakota" },
  { zipcode: "47", name: "Tennessee" },
  { zipcode: "48", name: "Texas" },
  { zipcode: "49", name: "Utah" },
  { zipcode: "50", name: "Vermont" },
  { zipcode: "51", name: "Virginia" },
  { zipcode: "53", name: "Washington" },
  { zipcode: "54", name: "West Virginia" },
  { zipcode: "55", name: "Wisconsin" },
  { zipcode: "56", name: "Wyoming" },
];
