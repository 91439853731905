import React from "react";
import { Button, Result } from "antd";
import { FormattedMessage } from "react-intl";

import ErrorBoundary from "./ErrorBoundary";

function TopLevelErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      error={
        <Result
          status="500"
          title="500"
          subTitle={
            <FormattedMessage
              description="500 page message"
              defaultMessage="Sorry, something went wrong."
            />
          }
          extra={[
            <Button type="primary" onClick={() => window.location.reload()}>
              <FormattedMessage
                description="500 page try again button"
                defaultMessage="Try Again"
              />
            </Button>,
            <Button href="/">
              <FormattedMessage
                description="500 page back home button"
                defaultMessage="Back Home"
              />
            </Button>,
          ]}
        />
      }
    >
      {children}
    </ErrorBoundary>
  );
}

export default TopLevelErrorBoundary;
