import { useMemo } from "react";
import { generatePath, useRouteMatch } from "react-router-dom";
import _isEmpty from "lodash/fp/isEmpty";

const PATH = "/atlas/:category/:statistic";

/**
 * Creates a route (url path) from the given selection criteria.
 */
function selectionToRoute({ category, statistic }) {
  return generatePath(PATH, {
    category: underscoreToDash(category),
    statistic: underscoreToDash(statistic),
  });
}

/**
 * Extract selection criteria from current route.
 */
function useSelectionFromRoute() {
  const match = useRouteMatch([PATH, "/atlas"]);
  // We need to guarantee that the return value of this hook has the same
  // object identity if the value hasn't changed.
  const selection = useMemo(() => {
    return {
      category: dashToUnderscore(match.params.category),
      statistic: dashToUnderscore(match.params.statistic),
    };
  }, [match.params.category, match.params.statistic]);
  if (_isEmpty(match.params)) {
    return null;
  } else {
    return selection;
  }
}

function dashToUnderscore(value) {
  if (value === null || value === undefined) {
    return null;
  } else {
    return value.replaceAll("-", "_");
  }
}
function underscoreToDash(value) {
  if (value === null || value === undefined) {
    return null;
  } else {
    return value.replaceAll("_", "-");
  }
}

export { selectionToRoute, useSelectionFromRoute };
