import { useIntl, defineMessages } from "react-intl";
import ReactHtmlParser from "react-html-parser";

function useDescription(statistic) {
  const intl = useIntl();
  if (statistic === null) {
    return null;
  } else if (DESCRIPTIONS.hasOwnProperty(statistic)) {
    return (
      <div>{ReactHtmlParser(intl.formatMessage(DESCRIPTIONS[statistic]))}</div>
    );
  } else {
    throw new Error(`Missing description for statistic ${statistic}`);
  }
}

const DESCRIPTIONS = defineMessages({
  // economy
  below_poverty: {
    id: "description.below_poverty",
    defaultMessage: `
      Poverty in the United States is the minimum amount of annual income
      earned by a household that is needed to afford basic needs. The poverty
      level is set at a threshold that varies by family size and age of the
      members. With an income below the defined threshold, members of the
      community will be less likely to have access to healthy food, health
      insurance, and many other resources that lead to a healthy life.

      Source: census.gov (United States Census Bureau)
  `,
  },
  household_income_median: {
    id: "description.household_income_median",
    defaultMessage: `
      Household income is a measure of the amount of money that everyone living
      in the same household earns per year. Household income can be a social
      determinant of health within the community because households that earn
      more money are able to obtain more resources that improve health (e.g.,
      healthier food, gym memberships, and healthcare). The median household
      income is the middle value of all household incomes (e.g, $20,000 is the
      median value of this list of household incomes: $10,000, $15,000,
      $20,000, $50,000, $60,000).
  `,
  },
  owned_one_or_more_vehicles: {
    id: "description.owned_one_or_more_vehicles",
    defaultMessage: `
      This represents the proportion of households that own at least one passenger 
      car, van, pickup truck or panel truck weighing 2,000 pounds or less that 
      is available for the use of household members. Vehicle ownership is 
      considered a proxy for affluence. The ownership of a personal vehicle 
      increases personal autonomy and access to jobs and services. 
    `,
  },
  unemployed: {
    id: "description.unemployed",
    defaultMessage: `
      This represents the percent of the population that is unemployed. Whereas
      a good-paying job makes it easier for workers to live in healthier
      neighborhoods, have greater access to resources, and buy more nutritious
      food, unemployment is associated with a number of negative health
      effects.  Unemployed individuals are more likely develop stroke, heart
      attacks, heart disease, and depression.
    `,
  },

  // education
  gte_bachelor_degree: {
    id: "description.gte_bachelor_degree",
    defaultMessage: `
      A bachelor’s degree indicates that a person has graduated from a
      four-year college or university. The percentage displayed includes those
      who have at least a bachelor’s degree and it includes individuals who
      have achieved higher degrees (e.g., masters, doctorate). Individuals with
      higher levels of education often have higher paying jobs, allowing them
      to have better access to resources and healthcare.
  `,
  },
  lte_high_school: {
    id: "description.lte_high_school",
    defaultMessage: `
      Indicates that a person has an education level equal to a high school degree or less . Individuals with less formal education often have lower paying jobs, allowing them to have less access to resources and healthcare.
    `,
  },

  // environment
  acetaldehyde: {
    id: "description.acetaldehyde",
    defaultMessage: `
      Acetaldehyde is a pollutant that can negatively impact health. It has
      been shown to cause cancer in rats and is reasonably anticipated to be a
      human carcinogen.
    `,
  },
  gas_1_3_butadiene: {
    id: "description.gas_1_3_butadiene",
    defaultMessage: `
      1,3-Butadiene is a pollutant that is known to cause cancer in humans.

      '<a' href="https://ntp.niehs.nih.gov/ntp/roc/content/profiles/butadiene.pdf"'>'Source'</a>'
    `,
  },
  benzene: {
    id: "description.benzene",
    defaultMessage: `
      Benzene is a highly flammable, sweet-smelling chemical that is 
      widely used in the United States. Long term exposure to benzene 
      can lead to anemia, excessive bleeding, and a weakened immune system. 
    `,
  },
  carbon_tetrachloride: {
    id: "description.carbon_tetrachloride",
    defaultMessage: `
      Carbon tetrachloride is a chemical that can be found in both indoor 
      and outdoor air. Acute exposure symptoms include headache, weakness,
      lethargy, nausea, and vomiting. Higher-level or long-term exposures
      to carbon tetrachloride can lead to liver and kidney damage.
    `,
  },
  diesel_pm: {
    id: "description.diesel_pm",
    defaultMessage: `
      Diesel particulate matter, commonly referred to as “soot,” causes
      increased hospital admissions for heart disease and respiratory 
      illness. High diesel PM concentrations have also been shown to 
      be related to the onset of new allergies. 
    `,
  },
  ethylbenzene: {
    id: "description.ethylbenzene",
    defaultMessage: `
      Ethylbenzene is a chemical that can produce negative respiratory 
      effects in humans, including throat irritation and chest constriction. 
      Studies on animals have reported that long-term exposure may result 
      in harmful effects on the blood, liver, and kidneys.
    `,
  },
  formaldehyde: {
    id: "description.formaldehyde",
    defaultMessage: `
      Formaldehyde is a strong-smelling, flammable gas used in many household 
      products, as well as fertilizers and pesticides. Exposure can cause 
      irritation of the skin, eyes, nose, and throat. Long-term exposure 
      may cause cancer.
    `,
  },
  hexane: {
    id: "description.hexane",
    defaultMessage: `
      Hexane is a chemical used in oil extraction and in cleaning agents. 
      Acute exposure can lead to  dizziness, giddiness, slight nausea, 
      and headache. Chronic exposure is associated with polyneuropathy, 
      or nerve malfunctioning with symptoms including numbness, muscular 
      weakness, blurred vision, and headache.
    `,
  },
  lead_compounds: {
    id: "description.lead_compounds",
    defaultMessage: `
      Lead is a chemical used in manufacturing. Early childhood exposure 
      is associated with cognitive development issues and learning 
      deficits. Exposure to high amounts of lead can cause gastrointestinal 
      issues, damage the brain and kidneys, and cause reproductive effects.
    `,
  },
  manganese_compounds: {
    id: "description.manganese_compounds",
    defaultMessage: `
      Manganese is a trace element that is essential for human health. 
      However, exposure to large amounts of the manganese compounds 
      used in manufacturing can lead to nervous system effects such as 
      behavior change and impaired movement.
    `,
  },
  mercury_compounds: {
    id: "description.mercury_compounds",
    defaultMessage: `
      Mercury is a highly toxic naturally occurring element. Prenatal 
      to mercury can cause developmental effects, while exposure 
      during adulthood can harm the nervous, digestive and immune 
      systems, lungs and kidneys, and may be fatal.
    `,
  },
  methanol: {
    id: "description.methanol",
    defaultMessage: `
      Methanol is a chemical used in manufacturing. Exposure to large 
      amounts of methanol can cause a range of negative health effects, 
      including gastrointestinal symptoms, vision disturbances, and 
      electrolyte imbalances. Additionally, methanol exposure may 
      cause birth defects of the central nervous system.
    `,
  },
  methyl_chloride: {
    id: "description.methyl_chloride",
    defaultMessage: `
      Methyl chloride is a chemical used mainly in the production of 
      silicones. Acute exposure to large amounts of methyl chloride 
      has been shown to cause severe neurological effects, while 
      chronic exposure may cause liver, kidney, spleen, and central 
      nervous system effects.
    `,
  },
  nickel_compounds: {
    id: "description.nickel_compounds",
    defaultMessage: `
      Nickel is a naturally occurring element that is used in manufacturing. 
      Exposure to high amounts of nickel commonly causes nickel dermatitis, 
      a skin condition characterized by itching of the fingers, hands and 
      forearms. Inhalation exposure to nickel may also cause respiratory effects.
    `,
  },
  pm25: {
    id: "description.pm25",
    defaultMessage: `
      Air pollution includes substances in the atmosphere that are harmful to
      health . There are different types and sizes of air pollutants.
      Particulate matter is a mixture of solid particles and liquid droplets
      that are found in the air. Particulate matter size 2.5 micrometers
      (PM2.5) include fine inhalable particles with a diameter of 2.5
      micrometers and are a particularly harmful subset of are pollutants. Some
      sources of PM2.5 include: construction sites, smokestacks, and chemical
      reactions from power plants and automobiles. PM2.5 can enter the lungs
      and bloodstream when inhaled, resulting in conditions such as decreased
      lung function, asthma, and heart attacks. The blocks groups within Durham
      county that display higher values of PM2.5 may have less clean air,
      potentially increasing risk for adverse health outcomes.

      Source: EPA.gov
  `,
  },
  toluene: {
    id: "description.toluene",
    defaultMessage: `
      Toluene is a sweet-smelling, colorless liquid used in many products 
      including paints, dyes, and gasoline. Chronic or high-level exposure 
      can cause many negative health effects, including eye and nose 
      irritation, fatigue, confusion, euphoria, dizziness, headache, 
      anxiety, insomnia, nerve damage, inflammation and liver and kidney 
      damage.
    `,
  },
  xylenes: {
    id: "description.xylenes",
    defaultMessage: `
      Xylenes are chemicals released into the air as emissions from 
      industrial sources and auto exhaust. Acute exposures can result 
      in eye and nose irritation, gastrointestinal issues, and 
      neurological effects. Chronic exposure causes mostly central 
      nervous system (CNS) effects, but respiratory, cardiovascular, 
      and kidney effects have also been reported.
    `,
  },

  // health
  ckd: {
    id: "description.ckd",
    defaultMessage: `
      People with chronic kidney disease have kidneys that are damaged 
      and cannot filter blood properly. Risk factors include diabetes, 
      high blood pressure, heart disease, and family history. The Centers 
      for Disease Control (CDC) uses one definition to identify 
      individuals with chronic kidney disease (CKD) based on the rate 
      at which a person’s kidneys filter urine. A higher glomerular 
      filtration rate (GFR), the better the health of a person’s kidney. 
      The Kidney Disease Improving Global Outcomes (KDIGO) consortium 
      uses a different definition of CKD that is also based on GFR.
      `,
  },

  ckd_cdc_lt_30: {
    id: "description.ckd_cdc_lt_30",
    defaultMessage: `
      Stage 4 chronic kidney disease based on the Centers for Disease Control definition. Severe reduction in kidney function. 
      `,
  },
  ckd_cdc_lt_60: {
    id: "description.ckd_cdc_lt_60",
    defaultMessage: `
     Stage 3 chronic kidney disease based on the Centers for Disease Control definition. Moderate decrease in kidney function
      `,
  },
  ckd_kdigo_lt_20: {
    id: "description.ckd_kdigo_lt_20",
    defaultMessage: `
      KDIGO < 20 - 
      `,
  },
  ckd_kdigo_lt_30: {
    id: "description.ckd_kdigo_lt_30",
    defaultMessage: `
      Stage 3b – moderately to severely decreased kidney function based on the Kidney Disease Improving Global Outcomes (KDIGO) definition
      `,
  },
  ckd_kdigo_lt_60: {
    id: "description.ckd_kdigo_lt_60",
    defaultMessage: `
      Stage 3a – mild to moderately decreased kidney function based on the Kidney Disease Improving Global Outcomes (KDIGO) definition.
      `,
  },
  htn: {
    id: "description.htn",
    defaultMessage: `
    High blood pressure (hypertension) is a common condition in which the 
    long-term force of the blood against your artery walls is high enough 
    that it may eventually cause health problems, such as heart disease.
      `,
  },
  htn_bp: {
    id: "description.htn_bp_htn",
    defaultMessage: `
      Blood transported through the body’s arteries is constantly 
      pushing against the artery walls, which causes blood pressure. 
      High blood pressure occurs when one’s blood pressure is 
      consistently above the normal limit. If an adult’s blood pressure 
      is typically above normal values, then a health care professional 
      may diagnose the patient with high blood pressure. It is important 
      to both prevent and treat high blood pressure as this disease can 
      increase one’s risk of developing other diseases such as heart 
      disease, stroke, chronic kidney disease, and dementia. 
      Source: '<a' href="https://www.cdc.gov/bloodpressure/about.htm"'>'CDC.gov'</a>'
      `,
  },
  htn_diag: {
    id: "description.htn_diag_htn",
    defaultMessage: `
      Hypertension is synonymous with high blood pressure (see above 
        indicator). This represents the number of people who have 
        been diagnosed with hypertension by their health care providers.
      `,
  },
  obesity: {
    id: "description.obesity",
    defaultMessage: `
        Obesity involves having an excessive amount of body fat.
        Obesity increases the risk of heart disease, diabetes,
        high blood pressure and certain cancers. Risk factors 
        for obesity include family history, unhealthy diet, 
        physical inactivity, and certain diseases and medications. 
        Obesity classifications are based on Body Mass Index (BMI) 
        which estimate body fat levels based on height and weight.
      `,
  },
  obesity_class_1: {
    id: "description.obesity_class_1",
    defaultMessage: `BMI 30.0 to 34.9. Class 1 Obesity.
      `,
  },
  obesity_class_2: {
    id: "description.obesity_class_2",
    defaultMessage: `BMI 35.0 to 39.9. Class 2 Obesity.
      `,
  },
  obesity_class_3: {
    id: "description.obesity_class_3",
    defaultMessage: `BMI greater than or equal to 40. Class 3 Obesity.
      `,
  },
  obesity_ow: {
    id: "description.obesity_ow",
    defaultMessage: `
      Not obese but having body fat levels higher than recommended,
      BMI  25.0 to 29.9.
      `,
  },
  obesity_total: {
    id: "description.obesity_total",
    defaultMessage: `
      Total proportion of people who are obese or overweight.
      `,
  },
  lbw: {
    id: "description.lbw",
    defaultMessage: `
      Low birth weight describes babies who are born weighing less than 
      5 pounds, 8 ounces. Babies with low birth weight can be healthy, but 
      they may also have serious health problems including jaundice, trouble 
      gaining weight, higher risk for infection, and nervous system and 
      breathing problems. Very low birth weight is related to cerebral palsy,
      blindness, deafness and developmental delay. Risk factors for 
      low birth weight include premature birth, infection or lack of 
      weight gain during pregnancy, age less than 17 or older than 35 
      during pregnancy, and smoking or alcohol use during pregnancy.
      `,
  },
  lbw_preterm: {
    id: "description.lbw_preterm",
    defaultMessage: `
      Babies are considered pre-term if they are born before 37 weeks into 
      the pregnancy. Pre-term births occur due to a variety of causes 
      including maternal age, high blood pressure, diabetes, and 
      previous pregnancies. Babies born pre-term have an increased 
      risks for health complications, learning disabilities, and neonatal demise.
      Source: '<a' href="https://www.who.int/news-room/fact-sheets/detail/preterm-birth"
      target="_blank"'><u>'Who.int'</u></a>'  
    `,
  },
  lbw_late_preterm: {
    id: "description.lbw_late_preterm",
    defaultMessage: `
      Late pre-term birth occurs when babies are born between 32 and 37 weeks into the pregnancy. 
      Source: '<a' href="https://www.who.int/news-room/fact-sheets/detail/preterm-birth"
      target="_blank"'><u>'Who.int'</u></a>'
    `,
  },
  lbw_very_preterm: {
    id: "description.lbw_very_preterm",
    defaultMessage: `
      Source: '<a' href="https://www.who.int/news-room/fact-sheets/detail/preterm-birth"
      target="_blank"'><u>'Who.int'</u></a>'
    `,
  },
  lbw_extrem_preterm: {
    id: "description.lbw_extrem_preterm",
    defaultMessage: `
      Extremely pre-term birth occurs when babies are born before 28 weeks into the pregnancy. 
      Source: '<a' href="https://www.who.int/news-room/fact-sheets/detail/preterm-birth"
      target="_blank"'><u>'Who.int'</u></a>' 
    `,
  },
  lbw_very_low: {
    id: "description.lbw_very_low",
    defaultMessage: `Very low birth weight is defined as babies born weighing less than 1500 grams.`,
  },
  lbw_low: {
    id: "description.lbw_low",
    defaultMessage: `Low birth weight is defined as babies born weighing less than 2500 grams.`,
  },
  mi: {
    id: "description.mi",
    defaultMessage: `
      A myocardial infarction, also known as a heart attack, happens 
      when one or more areas of the heart muscle don't get enough oxygen.

      A heart attack is a medical emergency.
      `,
  },
  mi_diag: {
    id: "description.mi_diag",
    defaultMessage: `
      Diagnosed with a heart attack. A heart attack occurs when a blood clot blocks blood flow to the heart.
      `,
  },

  stroke: {
    id: "description.stroke",
    defaultMessage: `
      A stroke occurs when a blood vessel that carries oxygen and nutrients 
      to the brain is either blocked by a clot or bursts (or ruptures). 
      When that happens, part of the brain cannot get the blood 
      (and oxygen) it needs, so it and brain cells die.
      `,
  },
  stroke_diag: {
    id: "description.stroke_diag",
    defaultMessage: `
      Stroke among adults aged >=18 years. A stroke occurs when a clot blocks blood supply to the brain or a blood vessel in the brain bursts. 
      `,
  },

  diabetes: {
    id: "description.diabetes",
    defaultMessage: `
      Diabetes is a chronic (long-lasting) health condition that affects
      how your body turns food into energy.
    `,
  },
  diabetes_diag: {
    id: "description.diabetes_diag",
    defaultMessage: `
      Patients >=18 years of age at Duke or Lincoln Community Health Center with a Durham County 
      address who had a ICD diagnosis for diabetes. 
      Source: Duke University Health System and Lincoln Community Health Center     
    `,
  },

  // housing
  gross_rent_median: {
    id: "description.gross_rent_median",
    defaultMessage: `
      Median gross rent is the midpoint value of rent individual households pay
      per month in each neighborhood. The median gross rent includes the
      contract rent, cost of utilities, and heating fuel if the household pays
      these costs. If a particular block group within Durham county has a lower
      average gross rent, there is the likelihood that the homes in that block
      group are less maintained and do not have as easy access to affordable
      food, properly maintained roads, and other resources that can affect the
      health of the community.
   `,
  },
  home_value_median: {
    id: "description.home_value_median",
    defaultMessage: `
      Median home value is the midpoint value of a home in a particular block
      group. Neighborhoods with low median home values may experience poor
      living conditions, which could increase stress, social isolation, unsafe
      living conditions, and increase risk for negative health conditions.
      Furthermore these households are likely to have more resources to
      allocate to other health resources such as healthy food and required
      medicines.
    `,
  },
  person_one_more_room: {
    id: "description.person_one_more_room",
    defaultMessage: `
      A measure of overcrowding defined by the number of household inhabitants 
      divided by the number of rooms in a household unit. Overcrowding can act 
      as a proxy for wealth and is an important social determinant of health 
      because it may affect mental health and sleep and can increase the 
      spread of infectious disease.
    `,
  },
  eviction_count: {
    id: "description.eviction_count",
    defaultMessage: `
      The eviction count is the number of renters ordered to leave their homes. 
    `,
  },
  eviction_rate: {
    id: "description.eviction_rate",
    defaultMessage: `
      The eviction rate is the subset of eviction filings that received an eviction 
      judgement in which renters were ordered to leave divided by the number of 
      renter-occupied homes in that area. This only counts a single address who 
      received an eviction judgement.
    `,
  },
  eviction_filings: {
    id: "description.eviction_filings",
    defaultMessage: `
      A count of all eviction cases filed in an area, including multiple cases filed 
      against the same address in the same year. 
    `,
  },
  eviction_filing_rate: {
    id: "description.eviction_filing_rate",
    defaultMessage: `
      The eviction filing rate is the ratio of the number of evictions filed 
      in an area divided by the number of renter-occupied homes in that area.
    `,
  },

  out_of_state_ownership: {
    id: "description.out_of_state_ownership",
    defaultMessage: `
      This number is the percent of properties owned by individuals or companies 
      that are outside of North Carolina. These can be national or international 
      firms or individuals. Some out of state companies use shell LLCs located in 
      Durham as the deeded owner of the property so they are not counted in these data. 
    `,
  },
  cost_burdened_morgage_holders: {
    id: "description.cost_burdened_morgage_holders",
    defaultMessage: `
      A household is cost burdened if they spend more than 30% of their 
      income on housing. A household is severely cost burdened if they 
      spend more than 50% of their income on housing.
      `,
  },
  cost_burdened_renters: {
    id: "description.cost_burdened_renters",
    defaultMessage: `
      Rent burden is defined as spending more than 30 percent of household 
      income on rent. In 2009, more than half of renter-occupied households 
      with children (54 percent) experienced rent burden. Between 2002 and 2009, 
      the proportion of households with children affected by rent burden 
      increased significantly.
      `,
  },

  // indices
  adi: {
    id: "description.adi",
    defaultMessage: `
     The index is based on a person's block group of residence and includes 17 variables across domains of education, income/unemployment,
     housing, and household characteristics. Values can range from 0-100; values in the map are state level decile rankings.
     Click '<a' href="http://www.cdc.gov/pcd/issues/2016/16_0221.htm"
     target="_blank"'><u>'ADI'</u></a>' to find more.
    `,
  },
  ahrq_ses: {
    id: "description.ahrq_ses",
    defaultMessage: `
      The index is based on a person's block group or census track of residence and includes the following 7 Census variables: 
      percentage of people in the labor force who are unemployed, percentage of people living below poverty level, median household
      income, median value of owner-occupied dwellings, percentage of people ≥25 years of age with less than a 12th-grade education,
      percentage of people ≥25 years of age completing ≥4 years of college, and percentage of households that average ≥1 people per room.
      Click '<a' href="https://www.ahajournals.org/doi/10.1161/circoutcomes.113.000520" target="_blank"'><u>'AHRQ Index'</u></a>'
      to learn more
    `,
  },
  coi: {
    id: "description.coi",
    defaultMessage: `
      The Child Opportunity Index (COI) measures and maps the quality of resources and conditions that matter for 
      children to develop in a healthy way in the neighborhoods where they live.
      Click '<a' href="http://www.diversitydatakids.org/child-opportunity-index"
      target="_blank"'><u>'COI'</u></a>' to learn more.
    `,
  },
  svi: {
    id: "description.svi",
    defaultMessage: `
      The index is based on a person's block group or census track of residence and includes the following 7 Census variables: 
      percentage of people in the labor force who are unemployed, percentage of people living below poverty level, 
      median household income, median value of owner-occupied dwellings, percentage of people ≥25 years of age with less 
      than a 12th-grade education, percentage of people ≥25 years of age completing ≥4 years of college, and percentage of 
      households that average ≥1 people per room. Click '<a' href="https://www.ahajournals.org/doi/10.1161/circoutcomes.113.000520"
      target="_blank"'><u>'SVI'</u></a>' to find out more.
    `,
  },
  hvi: {
    id: "description.hvi",
    defaultMessage:
      "The heat vulnerability index (HVI) combines census tract level information on race, ethnicity, age, education, employment status, poverty, housing, disability, country of origin, diabetes status, vegetation coverage, and air temperature to identify areas and populations at greatest risk from the effects of heat.",
  },

  // infrastructure
  bar_nightclub: {
    id: "description.bar_nightclub",
    defaultMessage: `
      Research suggests that neighborhoods with high concentrations of businesses 
      that serve alcohol can increase rates of crime. This can negatively impact health. 
    `,
  },
  bb: {
    id: "description.bb",
    defaultMessage: `Broadband.`,
  },
  bb_any_connection: {
    id: "description.bb_any_connection",
    defaultMessage: `Number of Fixed Residential Broadband Providers, any speed (count).`,
  },
  bb_high_speed: {
    id: "description.bb_high_speed",
    defaultMessage: `Number of Fixed Residential Broadband Providers, High Speed (count).`,
  },
  parks: {
    id: "description.parks",
    defaultMessage: `
      Parks provide the community with a location to exercise and enjoy the
      outdoors. Physical Activity and exercise reduce risk for multiple adverse
      health outcomes. With more resources conveniently available, community
      members are more likely to go to the parks and engage in physical
      activity.
    `,
  },
  hospitals: {
    id: "description.hospitals",
    defaultMessage: `
      Hospitals allow individuals to seek healthcare more easily. This in turn
      allows people to manage their health better, resulting in fewer adverse
      health outcomes.
    `,
  },
  impervious_area: {
    id: "description.impervious_area",
    defaultMessage: `
      Impervious surfaces include surfaces that prevent water from draining naturally 
      into the ground. This can include roads, parking lots, sidewalks, and rooftops. 
      Impervious surfaces increase erosion, reduce water quality, and create areas 
      where temperatures are higher than areas with less impervious surfaces 
      (often called “heat islands”). 
    `,
  },
  liquor_stores: {
    id: "description.liquor_stores",
    defaultMessage: `
      Neighborhoods a greater number of liquor stores can have higher rates of 
      alcohol-related hospitalizations, drunk driving accidents, and pedestrian 
      injuries. They can also increase the number of criminal and nuisance activities. 
      These all impact health. 
   `,
  },
  religious_locations: {
    id: "description.religious_location",
    defaultMessage: `
      Religious institutions often serve as a source of social support for individuals 
      and may serve a positive influence on one's health. 
    `,
  },
  tobacco_retail_outlets: {
    id: "description.tobacco_retail_outlets",
    defaultMessage: `
      Tobacco retail outlets (TROs) are stores that have tobacco and tobacco
      related products for purchase. Examples of TROs includes gas stations and
      grocery stores. Tobacco has been proven to cause cancer and lung disease;
      it also includes the addictive chemical nicotine. The availability of
      tobacco within different areas of the community can influence the amount
      of tobacco consumed which will affect the health of individuals who use
      tobacco products. TROs were identified in Durham County by reviewing
      existing business records, online resources like Google Maps, other
      databases, and by walking through neighborhoods. All potential TROs were
      visited and confirmed to sell tobacco.
  `,
  },
  tree_coverage: {
    id: "description.tree_coverage",
    defaultMessage: `
      Urban tree cover provides benefits to human health and 
      well-being, but studies suggest that tree cover is often inequitably distributed.
    `,
  },
  walkability: {
    id: "description.walkability",
    defaultMessage: `
      This is based on the National Walkability Index, which ranks 
      block groups according to their relative walkability on a 
      scale from 1-20, with 1 referring to the least walkability 
      and 20 to the most walkability. Living in walkable neighborhoods 
      encourages physical activity, which can help to regulate body weight 
      and improve overall health.
      `,
  },

  //food access
  lilatracts_1and10: {
    id: "description.lilatracts_1and10",
    defaultMessage: `A low income tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 
      10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: '<a' href="https://www.ers.usda.gov/data-products/food-access-research-atlas/" target="_blank"'>'USDA'</a>'`,
  },
  lilatracts_halfand10: {
    id: "description.lilatracts_halfand10",
    defaultMessage: `A low-income tract with at least 500 people, or 33 percent of the population, living more than one-half mile (urban areas)
       or more than 10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: '<a' href="https://www.ers.usda.gov/data-products/food-access-research-atlas/" target="_blank"'>'USDA'</a>'`,
  },
  lilatracts_1and20: {
    id: "description.lilatracts_1and20",
    defaultMessage: `A low income tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 
      20 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: '<a' href="https://www.ers.usda.gov/data-products/food-access-research-atlas/" target="_blank"'>'USDA'</a>'`,
  },
  lilatracts_vehicle: {
    id: "description.lilatracts_vehicle",
    defaultMessage: `A low income tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 
      10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: '<a' href="https://www.ers.usda.gov/data-products/food-access-research-atlas/" target="_blank"'>'USDA'</a>'`,
  },
  la1and10: {
    id: "description.la1and10",
    defaultMessage: `A tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 
      10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: '<a' href="https://www.ers.usda.gov/data-products/food-access-research-atlas/" target="_blank"'>'USDA.gov'</a>'`,
  },
  lahalfand10: {
    id: "description.lahalfand10",
    defaultMessage: `A tract with at least 500 people, or 33 percent of the population, living more than one-half mile (urban areas) or 
      10 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: '<a' href="https://www.ers.usda.gov/data-products/food-access-research-atlas/" target="_blank"'>'USDA.gov'</a>'`,
  },
  la1and20: {
    id: "description.la1and20",
    defaultMessage: `A tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 
      20 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: '<a' href="https://www.ers.usda.gov/data-products/food-access-research-atlas/" target="_blank"'>'USDA.gov'</a>'`,
  },
  latractsvehicle_20: {
    id: "description.latractsvehicle_20",
    defaultMessage: `A low income tract with at least 500 people, or 33 percent of the population, living more than 1 mile (urban areas) or 
      20 miles (rural areas) from the nearest supermarket, supercenter, or large grocery store. Source: '<a' href="https://www.ers.usda.gov/data-products/food-access-research-atlas/" target="_blank"'>'USDA'</a>'`,
  },

  et: {
    id: "description.et",
    defaultMessage: `Economy Transportation.`,
  },

  hi: {
    id: "description.hi",
    defaultMessage: `Health Insurance.`,
  },

  //new
  population: {
    id: "description.population",
    defaultMessage: "Percentage of population that is foreign-born",
  },
  rent_median: {
    id: "description.rent_median",
    defaultMessage: "Median gross rent (dollars)",
  },

  et_household_no_vehicle: {
    id: "description.et_household_no_vehicle",
    defaultMessage: "Percentage of housing units with no vehicle available",
  },
  non_institutionalized_population: {
    id: "description.non_institutionalized_population",
    defaultMessage: "Total civilian noninstitutionalized population",
  },

  gini_index: {
    id: "description.gini_index",
    defaultMessage:
      "A summary measure of income inequality. A value of 0 represents perfect equality and a value of 100 represents complete inequality.",
  },

  per_capita_income: {
    id: "description.per_capita_income",
    defaultMessage:
      "Per capita income (dollars, inflation-adjusted to data file year)",
  },
  household_food_stamps: {
    id: "description.household_food_stamps",
    defaultMessage:
      "Percentage of households that received food stamps/SNAP, past 12 months",
  },
  household_public_assistance: {
    id: "description.household_public_assistance",
    defaultMessage:
      "Percentage of households with public assistance income or food stamps/SNAP",
  },
  et_commute_time_15min: {
    id: "description.et_commute_time_15min",
    defaultMessage:
      "Percentage of workers with < 15-minute commute time (ages 16 and over)",
  },
  et_commute_time_29min: {
    id: "description.et_commute_time_29min",
    defaultMessage:
      "Percentage of workers with 15- to 29-minute commute time (ages 16 and over)",
  },
  et_commute_time_59min: {
    id: "description.et_commute_time_59min",
    defaultMessage:
      "Percentage of workers with 30- to 59-minute commute time (ages 16 and over)",
  },
  et_commute_time_60min_up: {
    id: "description.et_commute_time_60min_up",
    defaultMessage:
      "Percentage of workers with at least 60-minute commute time (ages 16 and over)",
  },
  et_drive_to_work: {
    id: "description.et_drive_to_work",
    defaultMessage:
      "Percentage of workers taking a car, truck, or van to work (ages 16 and over)",
  },
  et_public_commute_15min: {
    id: "description.et_public_commute_15min",
    defaultMessage:
      "Percentage of workers who use public transportation with < 15-minute commute time (ages 16 and over)",
  },
  et_public_commute_29min: {
    id: "description.et_public_commute_29min",
    defaultMessage:
      "Percentage of workers who use public transportation with 15- to 29-minute commute time (ages 16 and over)",
  },
  et_public_commute_59min: {
    id: "description.et_public_commute_59min",
    defaultMessage:
      "Percentage of workers who use public transportation with 30- to 59-minute commute time (ages 16 and over)",
  },
  et_public_commute_60min_up: {
    id: "description.et_public_commute_60min_up",
    defaultMessage:
      "Percentage of workers who use public transportation with at least 60-minute commute time (ages 16 and over)",
  },
  et_public_transit: {
    id: "description.et_public_transit",
    defaultMessage:
      "Percentage of workers taking public transportation, excluding taxicab (ages 16 and over)",
  },
  et_walk_to_work: {
    id: "description.et_walk_to_work",
    defaultMessage: "Percentage of workers walking to work (ages 16 and over)",
  },
  et_work_no_car: {
    id: "description.et_work_no_car",
    defaultMessage:
      "Percentage of workers in households with no vehicle available (ages 16 and over)",
  },
  graduate_degree: {
    id: "description.graduate_degree",
    defaultMessage:
      "Percentage of population with a master's or professional school degree or doctorate (ages 25 and over)",
  },
  hi_medicaid_any: {
    id: "description.hi_medicaid_any",
    defaultMessage:
      "Percentage of population with any Medicaid/means-tested public health insurance coverage",
  },
  hi_medicaid_any_below_64: {
    id: "description.hi_medicaid_any_below_64",
    defaultMessage:
      "Percentage of population with any Medicaid/means-tested public health insurance coverage (ages 64 and below)",
  },
  hi_medicare_only: {
    id: "description.hi_medicare_only",
    defaultMessage: "Percentage of population with Medicare only",
  },
  hi_private_employer_direct: {
    id: "description.hi_private_employer_direct",
    defaultMessage:
      "Percentage of population with employer-based and direct-purchase coverage",
  },
  hi_private_any: {
    id: "description.hi_private_any",
    defaultMessage:
      "Percentage of population with any private health insurance coverage",
  },
  hi_private_employer: {
    id: "description.hi_private_employer",
    defaultMessage:
      "Percentage of population with employer-based health insurance",
  },
  hi_private_medicare: {
    id: "description.hi_private_medicare",
    defaultMessage:
      "Percentage of population with employer-based and Medicare insurance coverage",
  },
  hi_private_self_employed: {
    id: "description.hi_private_self_employed",
    defaultMessage:
      "Percentage of population with direct-purchase health insurance only",
  },
  hi_medicare_public_only: {
    id: "description.hi_medicare_public_only",
    defaultMessage:
      "Percentage of population with Medicare, Medicaid, TRICARE/military, U.S. Department of Veterans Affairs (VA) coverage and otherpublic-only health insurance combinations",
  },
  hi_public_other: {
    id: "description.hi_public_other",
    defaultMessage:
      "Percentage of population with other public-only health insurance combinations",
  },
  hi_tricare_va: {
    id: "description.hi_tricare_va",
    defaultMessage:
      "Percentage of population with TRICARE/military or VA health insurance coverage only",
  },
  hi_uninsured: {
    id: "description.hi_uninsured",
    defaultMessage:
      "Percentage of population with no health insurance coverage",
  },
  civilian_labor_force: {
    id: "description.civilian_labor_force",
    defaultMessage:
      "Total population in the civilian labor force (ages 16 and over)",
  },
  civilian_employed_population: {
    id: "description.civilian_employed_population",
    defaultMessage: "Total civilian employed population (ages 16 and over)",
  },
  housing_units: {
    id: "description.housing_units",
    defaultMessage: "Total housing units",
  },
  households: {
    id: "description.households",
    defaultMessage: "Total number of households",
  },
  household_size_average: {
    id: "description.household_size_average",
    defaultMessage: "Average household size",
  },
  year_built_median: {
    id: "description.year_built_median",
    defaultMessage: "Median year structure built of housing units",
  },
  units_10_or_more: {
    id: "description.units_10_or_more",
    defaultMessage: "Percentage of housing structures with 10 or more units",
  },
  units_built_1979: {
    id: "description.units_built_1979",
    defaultMessage: "Percentage of housing units built before 1979",
  },
  different_state: {
    id: "description.different_state",
    defaultMessage:
      "Percentage of population that moved from different state in the past year (age 1 and over)",
  },
  occupied_house_no_fuel: {
    id: "description.occupied_house_no_fuel",
    defaultMessage: "Percentage of occupied housing units without fuel",
  },
  occupied_house_utility_gas: {
    id: "description.occupied_house_utility_gas",
    defaultMessage:
      "Percentage of occupied housing units with utility gas heating",
  },
  occupied_house_bottled_tank_lp_gas: {
    id: "description.occupied_house_bottled_tank_lp_gas",
    defaultMessage:
      "Percentage of occupied housing units with bottled, tank, or LP gas",
  },
  occupied_house_oil: {
    id: "description.occupied_house_oil",
    defaultMessage:
      "Percentage of occupied housing units with fuel oil heating",
  },
  occupied_house_wood: {
    id: "description.occupied_house_wood",
    defaultMessage: "Percentage of occupied housing units with wood heating",
  },
  occupied_house_coal: {
    id: "description.occupied_house_coal",
    defaultMessage:
      "Percentage of occupied housing units with coal or coke heating",
  },
  occupied_house_other: {
    id: "description.occupied_house_other",
    defaultMessage:
      "Percentage of occupied housing units with other heating fuel",
  },
  occupied_house_electricity: {
    id: "description.occupied_house_electricity",
    defaultMessage:
      "Percentage of occupied housing units with electricity heating",
  },
  occupied_house_solar_energy: {
    id: "description.occupied_house_solar_energy",
    defaultMessage: "Percentage of occupied housing units with solar energy",
  },
  in_county_move_1_year_ago: {
    id: "description.in_county_move_1_year_ago",
    defaultMessage:
      "Percentage of population that moved within the same county in the past year (age 1 and over)",
  },
  in_state_move_1_year_ago: {
    id: "description.in_state_move_1_year_ago",
    defaultMessage:
      "Percentage of population that moved from different county within same state in the past year (age 1 and over)",
  },
  lacking_kitchen_facilities: {
    id: "description.lacking_kitchen_facilities",
    defaultMessage:
      "Percentage of housing units lacking complete kitchen facilities",
  },
  owner_occupied_housing_units: {
    id: "description.owner_occupied_housing_units",
    defaultMessage: "Percentage of occupied housing units: owner-occupied",
  },
  owner_occupied_housing_units_with_children: {
    id: "description.owner_occupied_housing_units_with_children",
    defaultMessage: "Percentage of owner-occupied housing units with children",
  },
  lacking_plumbing_facilities: {
    id: "description.lacking_plumbing_facilities",
    defaultMessage:
      "Percentage of housing units lacking complete plumbing facilities",
  },
  renter_occupied_housing_units: {
    id: "description.renter_occupied_housing_units",
    defaultMessage: "Percentage of occupied housing units: rented",
  },
  vacant_housing_units: {
    id: "description.vacant_housing_units",
    defaultMessage: "Percentage of housing units vacant",
  },
  bb_no_computer_device: {
    id: "description.bb_no_computer_device",
    defaultMessage: "Percentage of households without a computing device",
  },
  bb_with_smartphone: {
    id: "description.bb_with_smartphone",
    defaultMessage: "Percentage of households with a smartphone",
  },
  bb_with_tablet_only: {
    id: "description.bb_with_tablet_only",
    defaultMessage:
      "Percentage of households with a tablet or other portable wireless computer with no other type of computing device",
  },
  bb_with_pc_only: {
    id: "description.bb_with_pc_only",
    defaultMessage:
      "Percentage of households with a desktop or laptop with no other type of computing device",
  },
  bb_with_internet_subscription: {
    id: "description.bb_with_internet_subscription",
    defaultMessage: "Percentage of households with any internet subscription",
  },
  bb_with_internet_no_subscription: {
    id: "description.bb_with_internet_no_subscription",
    defaultMessage:
      "Percentage of households with internet access without a subscription",
  },
  bb_with_broadband: {
    id: "description.bb_with_broadband",
    defaultMessage:
      "Percentage of households with broadband such as cable, fiber optic or DSL",
  },
  bb_with_broadband_no_internet_subscription: {
    id: "description.bb_with_broadband_no_internet_subscription",
    defaultMessage:
      "Percentage of households with broadband such as cable, fiber optic or DSL with no other type of Internet subscription",
  },
  bb_with_broadband_any: {
    id: "description.bb_with_broadband_any",
    defaultMessage: "Percentage of households with broadband of any type",
  },
  bb_no_internet_access: {
    id: "description.bb_no_internet_access",
    defaultMessage: "Percentage of households with no internet access",
  },
  cdc: {
    id: "description.cdc",
    defaultMessage: `500 Cities Project: Provided city and census tract level estimates for chronic disease risk factors, health outcomes, and clinical preventive services for the largest 500 cities in the United States from 2016-2019. The project included a total population of over 100 million people, representing 33% of the US population. More information can be found at&nbsp; Click '<a' href=\"https://www.cdc.gov/places/about/500-cities-2016-2019/index.html\" target=\"_blank\"'><u>'CDC'</u></a>'
      
    PLACES: The PLACES Project replaced the 500 Cities Project on December 2020 and expanded estimates on chronic disease risk factors, health outcomes, and clinical preventive services to the entire country`,
  },
  cdc_teeth_lost: {
    id: "description.cdc_teeth_lost",
    defaultMessage: "Percent of adults aged >=65 years with all teeth lost",
  },
  cdc_arthritis: {
    id: "description.cdc_arthritis",
    defaultMessage: "Percent of adults aged >=18 years with arthritis",
  },
  cdc_bingedrink: {
    id: "description.cdc_bingedrink",
    defaultMessage: "Percent of adults aged >=18 years that binge drink",
  },
  cdc_cancer: {
    id: "description.cdc_cancer",
    defaultMessage:
      "Percent of adults aged >=18 years with cancer (excluding skin cancer)",
  },
  cdc_cervical: {
    id: "description.cdc_cervical",
    defaultMessage:
      "Percent of adult women aged 21-65 years with cervical cancer screening",
  },
  cdc_cholesterol: {
    id: "description.cdc_cholesterol",
    defaultMessage:
      "Percent of adults aged >=18 years with cholesterol screening",
  },
  cdc_kidney: {
    id: "description.cdc_kidney",
    defaultMessage:
      "Percent of adults aged >=18 years with chronic kidney disease",
  },
  cdc_pulmonary: {
    id: "description.cdc_pulmonary",
    defaultMessage:
      "Percent of adults aged >=18 years with chronic obstructive pulmonary disease",
  },
  cdc_heart: {
    id: "description.cdc_heart",
    defaultMessage:
      "Percent of adults aged >=18 years with coronary heart disease",
  },
  cdc_asthma: {
    id: "description.cdc_asthma",
    defaultMessage: "Percent of adults aged >=18 years with asthma",
  },
  cdc_insurance: {
    id: "description.cdc_insurance",
    defaultMessage:
      "Percent of adults aged 18-64 years that currently lack health insurance",
  },
  cdc_smoking: {
    id: "description.cdc_smoking",
    defaultMessage:
      "Percent of adults aged >=18 years that are currently smoking",
  },
  cdc_depression: {
    id: "description.cdc_depression",
    defaultMessage: "Percent of adults aged >=18 years with depression",
  },
  cdc_diabetes: {
    id: "description.cdc_diabetes",
    defaultMessage: "Percent of adults aged >=18 years diagnosed with diabetes",
  },
  cdc_health: {
    id: "description.cdc_health",
    defaultMessage:
      "Percent of adults aged >=18 years with fair or poor self-rated health status",
  },
  cdc_fecal: {
    id: "description.cdc_fecal",
    defaultMessage:
      "Percent of adults aged 50-75 years with fecal occult blood test, sigmoidoscopy, or colonoscopy",
  },
  cdc_bphigh: {
    id: "description.cdc_bphigh",
    defaultMessage:
      "Percent of adults aged >=18 years with high blood pressure",
  },
  cdc_cholesterol_high: {
    id: "description.cdc_cholesterol_high",
    defaultMessage:
      "Percent of adults aged >=18 years who have been screened in the past 5 years with high cholesterol",
  },
  cdc_mammography: {
    id: "description.cdc_mammography",
    defaultMessage: "Percent of women aged 50-74 years with mammography use",
  },
  cdc_mental: {
    id: "description.cdc_mental",
    defaultMessage:
      "Percent of adults aged >=18 years with mental health not good for >=14 days",
  },
  cdc_leisure: {
    id: "description.cdc_leisure",
    defaultMessage:
      "Percent of adults aged >=18 years with no leisure-time physical activity",
  },
  cdc_obesity: {
    id: "description.cdc_obesity",
    defaultMessage: "Percent of adults aged >=18 years with obesity",
  },
  cdc_preventive_m: {
    id: "description.cdc_preventive_m",
    defaultMessage:
      "Percent of older adult men aged >=65 years who are up to date on a core set of clinical preventive services: Flu shot past year, PPV shot ever, Colorectal cancer screening",
  },
  cdc_preventive_f: {
    id: "description.cdc_preventive_f",
    defaultMessage:
      "Percent of older adult women aged >=65 years who are up to date on a core set of clinical preventive services: Flu shot past year, PPV shot ever, Colorectal cancer screening, and Mammogram past 2 years",
  },
  cdc_physical: {
    id: "description.cdc_physical",
    defaultMessage:
      "Percent of adults aged >=18 years with physical health not good for >=14 days",
  },
  cdc_sleep: {
    id: "description.cdc_sleep",
    defaultMessage:
      "Percent of adults aged >=18 years sleeping less than 7 hours",
  },
  cdc_strokes: {
    id: "description.cdc_strokes",
    defaultMessage: "Percent of adults aged >=18 years with stroke",
  },
  cdc_medicine_bp: {
    id: "description.cdc_medicine_bp",
    defaultMessage:
      "Percent of adults aged >=18 years with high blood pressure taking medicine for high blood pressure control",
  },
  cdc_dentist: {
    id: "description.cdc_dentist",
    defaultMessage:
      "Percent of adults aged >=18 years with visits to dentist or dental clinic",
  },
  cdc_doctor: {
    id: "description.cdc_doctor",
    defaultMessage:
      "Percent of adults aged >=18 years with visits to doctor for routine checkup within the past year",
  },
});

export default useDescription;
