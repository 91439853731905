import { useEffect, useReducer } from "react";
import _merge from "lodash/fp/merge";

import { useSelectionFromRoute } from "../route";

/**
 * @return an array of [selection, dispatch]. `selection` may be null.
 *
 * A selection is an object of the following type:
 * {
 *   level: !string,
 *   category: !string,
 *   statistic: !string,
 *   unit: !string,
 *   primaryDemographic: ?{
 *     type: !string,
 *     value: !string
 *   },
 *   secondaryDemographic: ?{
 *     type: !string,
 *     value: !string
 *   },
 *   year: !string,
 *   boundary: ?string,
 * }
 */
function useSelection() {
  const [selection, dispatch] = useReducer(selectReducer, {});

  const selectionFromRoute = useSelectionFromRoute();
  useEffect(() => {
    if (selectionFromRoute) {
      dispatch({
        type: "select-category",
        target: selectionFromRoute.category,
      });
      dispatch({
        type: "select-statistic",
        target: selectionFromRoute.statistic,
      });
    }
  }, [selectionFromRoute]);

  return [selection, dispatch];
}

function selectReducer(selection, action) {
  switch (action.type) {
    case "select-level":
      return _merge(selection, { level: action.target });
    case "select-countyFilter":
      return _merge(selection, { countyFilter: action.target });
    case "select-category":
      return _merge(selection, { category: action.target });
    case "select-statistic":
      return _merge(selection, { statistic: action.target });
    case "select-unit":
      return _merge(selection, { unit: action.target });
    case "select-year":
      return _merge(selection, { year: action.target });
    case "select-primary-demographic":
      return _merge(selection, { primaryDemographic: action.target });
    case "select-boundary":
      return _merge(selection, {
        boundary: action.target,
      });
    default:
      throw new Error(`Invalid action ${action}`);
  }
}

export default useSelection;
