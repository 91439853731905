export const EMPLOYER_CATEGORIES = [
  {
    value: "University / Academic / Healthcare",
    label: "University / Academic / Healthcare",
  },
  {
    value: "Federal government",
    label: "Federal government",
  },
  {
    value: "State / Local government",
    label: "State / Local government",
  },
  {
    value: "Private industry",
    label: "Private industry",
  },
  {
    value: "Community based organization",
    label: "Community based organization",
  },
  {
    value: "Community member",
    label: "Community member",
  },
  {
    value: "Other",
    label: "Other",
  },
];
