import { Button, Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import colors from "../../colors";
import Logo from "../Logo";
import "./CredentialsHeader.css";

function CredentialsHeader() {
  return (
    <nav
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "24px 64px",
      }}
    >
      <Row
        justify="space-between"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <Col>
          <Link to="/">
            <Logo type="primary" isHomePage="true" />
          </Link>
        </Col>
        <Col>
          <Button
            href="/"
            style={{
              color: colors.white,
              backgroundColor: "transparent",
              borderColor: colors.secondaryBlue,
              padding: "8px 16px",
              borderRadius: 30,
              fontSize: 14,
              fontWeight: 700,
            }}
            className="header-button"
            size="large"
          >
            <FormattedMessage
              description="Return to Home"
              defaultMessage="Return to Home"
            />
          </Button>
        </Col>
      </Row>
    </nav>
  );
}

export default CredentialsHeader;
