import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import {
  AZURE_APPLICATION_ID,
  AZURE_INGESTION_URL,
  AZURE_LIVE_URL,
  AZURE_MONITOR_KEY,
} from "../../constants";

const CONNECTION_STRING = `InstrumentationKey=${AZURE_MONITOR_KEY};IngestionEndpoint=${AZURE_INGESTION_URL};LiveEndpoint=${AZURE_LIVE_URL};ApplicationId=${AZURE_APPLICATION_ID}`;

const browserHistory = createBrowserHistory({ basename: "" });

const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();

const clickPluginConfig = {
  autoCapture: true,
};

export const appInsights = new ApplicationInsights({
  config: {
    connectionString: CONNECTION_STRING,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});
