import { Input } from "antd";
import colors from "../../colors";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

function InputPassword({ placeholder, ...props }) {
  return (
    <Input.Password
      placeholder={placeholder}
      size="large"
      style={{
        backgroundColor: "#0F2B43",
        border: "1px solid #00529B",
        padding: 16,
        color: colors.gray[300],
        height: 54,
      }}
      iconRender={(visible) =>
        visible ? (
          <EyeOutlined style={{ color: colors.white }} />
        ) : (
          <EyeInvisibleOutlined style={{ color: colors.white }} />
        )
      }
      {...props}
    />
  );
}

export default InputPassword;
