import { useLocation } from "react-router-dom";
import AppHeader from "./AppHeader";
import CredentialsHeader from "./CredentialsHeader";
import { isCredentialsRoute, isHomeRoute } from "../../utils";

function Header() {
  const location = useLocation();

  const hasCrendentialsRoute = isCredentialsRoute(location?.pathname);
  const hasHomeRoute = isHomeRoute(location?.pathname);

  return hasCrendentialsRoute ? (
    <CredentialsHeader />
  ) : (
    <AppHeader isHomePage={hasHomeRoute} />
  );
}

export default Header;
